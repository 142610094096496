import React from "react";
import { TableHead as MuiTableHead, TableRow, TableCell as MuiTableCell, TableSortLabel } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { TableCell } from "./Table";

const useCellStyles = makeStyles(theme => ({
  root: {
    fontSize: "inherit",
    paddingLeft: 0,
    whiteSpace: "nowrap",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    padding: "12px 0px 12px 16px !important", // pretty nasty, right?
  },
  sizeSmall: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },
}));

export interface TableHeadProps {
  cells: TableCell[];
  onOrder: (key: string) => void;
}

const TableHead: React.FC<TableHeadProps> = ({ cells, onOrder }) => {
  const cellStyles = useCellStyles();
  const cellToInt = (cell: TableCell) => (cell.size === "small" ? 1 : cell.size === "large" ? 3 : 2);
  const total = cells.reduce((accumulator, cell) => accumulator + cellToInt(cell), 0);

  return (
    <MuiTableHead>
      <TableRow>
        {cells.map(cell => (
          <MuiTableCell classes={cellStyles} key={cell.id} width={`${cellToInt(cell) * (100 / total)}%`}>
            {cell.sortable ? (
              <TableSortLabel
                active={cell.active}
                direction={cell.desc ? "asc" : "desc"}
                onClick={() => {
                  onOrder(cell.id);
                }}
              >
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </MuiTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
