import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Divider } from "@mui/material";
import { Place, Public, SignalCellularAlt } from "@mui/icons-material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { isEqual } from "lodash";
import { mapValues, mapValuesToSaveData } from "../../utils";
import allRoutes from "../../constants/routes";
import {
  initialValues,
  selectOperatorsSlice,
  setSavedValues,
  setValues as setOperatorsValues,
  setSubRegions,
  setAllSubRegions,
} from "../../store/slices/operators";
import { closeOperatorsFilters, selectUiState, setFiltersOpen, setOperatorsFilters } from "../../store/slices/ui";
import {
  useGetRegionsQuery,
  useGetChildRegionsQuery,
  useGetCountriesQuery,
  // useGetTechnologiesQuery,
  useGetSunsetTechsQuery,
  useGetUpgradeTechsQuery,
} from "../../store/apis/search";

import { useSaveSearchMutation } from "../../store/apis/save";
import { selectSaveToken } from "../../store/slices/auth";
import FilterList from "../FilterList";
import SearchFilter from "../SearchFilter";
import RadioFilter from "../RadioFilter";
import { privateOptions } from "../../constants";
import YearRangeFilter from "../YearRangeFilter";
import CheckboxFilter from "../CheckboxFilter";

const OperatorsForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { values: valuesCurrent, lastSaved, loading, filteredSubRegions } = useSelector(selectOperatorsSlice);
  const { operators: filters } = useSelector(selectUiState);
  const token = useSelector(selectSaveToken);

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(setFiltersOpen(false));
      dispatch(setOperatorsValues(values));

      const { path } = allRoutes.operators;

      if (pathname !== `${path}/search`) {
        navigate(`${path}/search`);
      }
    },
  });

  const { data: regionsOptions = [], isLoading: regionsLoading } = useGetRegionsQuery();
  const { data: switchoffTechnologiesOptions = [], isLoading: isSunsetTechnologiesLoading } = useGetSunsetTechsQuery();
  const { data: upgradeTechnologiesOptions = [], isLoading: isUpgradeTechnologiesLoading } = useGetUpgradeTechsQuery();
  const { data: childRegionOptions = [], isLoading: isChildRegionsLoading } = useGetChildRegionsQuery();
  const { data: countriesOptions = [], isLoading: countriesLoading } = useGetCountriesQuery({
    ituRegions: [],
    regions: values.regions,
  });

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  useEffect(() => {
    if (!isChildRegionsLoading) {
      // console.log("set all subRegions");
      dispatch(setAllSubRegions(childRegionOptions));
      dispatch(setSubRegions([]));
    }
  }, [childRegionOptions, dispatch, isChildRegionsLoading]);

  const setFilterOpen = useCallback(
    (key: string, value: boolean) => {
      dispatch(setOperatorsFilters({ ...filters, [key]: value }));
    },
    [dispatch, filters]
  );

  const [saveSearch] = useSaveSearchMutation();

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 4,
      data: mapValuesToSaveData(4, valuesCurrent),
    });
  };

  return (
    <FilterList
      loading={loading}
      disabled={regionsLoading || countriesLoading}
      canSearch={!isEqual(values, valuesCurrent)}
      canSave={!isEqual(valuesCurrent, lastSaved)}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onSave={handleSave}
    >
      <Box paddingX={4} paddingY={2} textAlign="right" bgcolor="grey.450">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch(closeOperatorsFilters());
          }}
        >
          Close all
        </Button>
      </Box>
      <Divider />
      <SearchFilter
        title="Search by name"
        name="operators-search"
        tooltip="Filter the search by Operator name"
        value={regionsOptions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={regionsOptions}
        loading={false}
        disabled={false}
        open={filters.search}
        noAutocomplete={true}
        onChange={value => {
          setFieldValue("search", value);
        }}
        setOpen={value => setFilterOpen("search", value)}
      />
      <SearchFilter
        title="Main Regions"
        name="operators-regions"
        tooltip="Filter the search by ITU Region"
        value={regionsOptions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={regionsOptions}
        loading={regionsLoading}
        disabled={!regionsOptions.length}
        open={filters.regions}
        onChange={value => {
          dispatch(setSubRegions(value.map(item => item.id)));
          setFieldValue("regions", mapValues(value));
        }}
        setOpen={value => setFilterOpen("regions", value)}
      />
      <SearchFilter
        title="Sub Regions"
        name="regions-filter"
        tooltip="Filter the search by Region"
        value={filteredSubRegions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={filteredSubRegions}
        loading={isChildRegionsLoading}
        disabled={!filteredSubRegions.length}
        open={filters.childRegions}
        onChange={value => {
          setFieldValue("ituRegions", []);
          setFieldValue("childRegions", mapValues(value));
          setFieldValue("regions", mapValues(value));

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("childRegions", value)}
      />
      <SearchFilter
        title="Country/Territories"
        name="operators-countries"
        tooltip="Filter by specific country"
        value={countriesOptions.filter(option => values.countries.includes(option.id))}
        icon={<Place fontSize="small" />}
        options={countriesOptions}
        loading={countriesLoading}
        disabled={!countriesOptions.length}
        open={filters.countries}
        onChange={value => setFieldValue("countries", mapValues(value))}
        setOpen={value => setFilterOpen("countries", value)}
      />
      <RadioFilter
        title="Public Status"
        name="public-filter"
        tooltip="Filter by public status"
        value={privateOptions.find(option => option.id === values.publicStatus)}
        icon={<SignalCellularAlt fontSize="small" />}
        options={privateOptions}
        open={filters.publicStatus}
        onChange={value => setFieldValue("publicStatus", value?.id)}
        setOpen={value => setFilterOpen("publicStatus", value)}
      />
      <YearRangeFilter
        title="Switch Off Year"
        name="year-range-filter"
        tooltip="Search by year range. "
        value={[values.startDate, values.endDate]}
        open={filters.dates}
        onChange={(value: (number | null)[]) => {
          const [startDate, endDate] = value;

          setFieldValue("startDate", startDate);
          setFieldValue("endDate", endDate);
        }}
        setOpen={(open: boolean) => setFilterOpen("dates", open)}
      />
      <CheckboxFilter
        title="Switch Off Technology"
        tooltip="What Technology has been switchoffted."
        value={switchoffTechnologiesOptions.filter(option => values.switchoffTechs.includes(option.id))}
        icon={<PowerSettingsNewIcon fontSize="small" />}
        options={switchoffTechnologiesOptions}
        loading={isSunsetTechnologiesLoading}
        open={filters.switchoffTechs}
        onChange={value => setFieldValue("switchoffTechs", mapValues(value))}
        setOpen={value => setFilterOpen("switchoffTechs", value)}
        testId="switchoffFilter"
      />
      <CheckboxFilter
        title="Upgrade Technology"
        tooltip="What Technology has been upgraded to."
        value={upgradeTechnologiesOptions.filter(option => values.upgradeTechs.includes(option.id))}
        icon={<UpgradeIcon fontSize="small" />}
        options={upgradeTechnologiesOptions}
        loading={isUpgradeTechnologiesLoading}
        open={filters.upgradeTechs}
        onChange={value => setFieldValue("upgradeTechs", mapValues(value))}
        setOpen={value => setFilterOpen("upgradeTechs", value)}
        testId="upgradeFilter"
      />
    </FilterList>
  );
};

export default OperatorsForm;
