import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { Themes } from "../../constants";
import { AppStateType } from "../";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    currentTheme: Themes.DEFAULT,
  },
  reducers: {
    setTheme: (state, { payload }: PayloadAction<Themes>) => {
      state.currentTheme = payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export const selectThemeState = (state: AppStateType) => state.theme;

export const selectTheme = createSelector(selectThemeState, state => state.currentTheme);

export default themeSlice.reducer;
