export const baseUrl = `${process.env.REACT_APP_API_HOST}`;
export const ssoUrl = `${process.env.REACT_APP_SSO_HOST}`;

export const prepareHeaders = (headers: Headers) => {
  const gambod = localStorage.getItem("gambod");
  if (gambod) {
    const state = JSON.parse(gambod);

    headers.set("Authorization", `Bearer ${state.token}`);
  }

  return headers;
};
