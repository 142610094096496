import { Avatar, Box, Button, Card, CardContent, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

export interface DashboardCardProps {
  title: string;
  icon?: React.ReactNode;
  body: string;
  path: string;
  color?: string;
  colorName?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, icon, body, path, color, colorName }) => {
  const theme = useTheme();
  const useCardStyles = makeStyles<Theme, { color?: string }>(() => ({
    root: {
      minHeight: "245px",
      boxShadow: `0px 2px 8px 0px rgba(0, 0, 0, 0.05)`,
      backgroundColor: theme.palette.background.default,
      borderTop: ({ color }) => `4px solid ${color || theme.palette.primary.main}`,
    },
  }));

  const useCardActionAreaStyles = makeStyles(() => ({
    root: {
      padding: theme.spacing(4),
    },
  }));

  const cardStyles = useCardStyles({ color });
  const cardActionAreaStyles = useCardActionAreaStyles();

  return (
    <Card classes={cardStyles}>
      {/* <Link href={path} style={{ textDecoration: "none" }}> */}
      {/* <CardActionArea classes={cardActionAreaStyles}> */}
      <CardContent>
        <Avatar sx={{ bgcolor: color }}>{icon && icon}</Avatar>
        <Box paddingY={4}>
          <Typography gutterBottom variant="h3" component="h3" style={{ color: color || "#ff0000" }}>
            {title}
          </Typography>
          <Typography gutterBottom color="textSecondary" component="p">
            {body}
          </Typography>
        </Box>
        {/* @ts-ignore */}
        <Button href={path} color={colorName} sx={{ color: "#fff" }} variant="contained">
          Start Search
        </Button>
      </CardContent>
      {/* </CardActionArea> */}
      {/* </Link> */}
    </Card>
  );
};

export default DashboardCard;
