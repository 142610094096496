import colors from "./colors";

const components = {
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        //padding: 0,
        //textAlign: "center",
        justifyContent: "center",
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "32px !important",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        minWidth: "150px",
        fontWeight: "bold",
        color: "#333",
        fontSize: "15px",
      },
    },
  },
  /* MuiBadge: {
    styleOverrides: {
      root: {
        //border: "1px solid red !important",
        minWidth: "32px !important",
      },
    },
  }, */
};

export default components;
