import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../constants";
import { useGetCountriesAsTreedataQuery } from "../../store/apis/search";
import { CountryTreeType } from "../../types";
import { PrivateNetworksValuesType } from "../../utils";
import TreeChartWithColors from "../TreeChartWithColors";

interface NetworkTreeChartByCountryProps {
  filters: PrivateNetworksValuesType;
  loading?: boolean;
}

interface TreeChartType {
  x: string;
  y: number;
}

const NetworkTreeChartByCountry: React.FC<NetworkTreeChartByCountryProps> = ({ filters, loading }) => {
  const [canvas, setCanvas] = useState<any>(null);
  const [mappedData, setmappedData] = useState<TreeChartType[] | null>(null);
  const { data } = useGetCountriesAsTreedataQuery({ values: filters });

  useEffect(() => {
    // console.log("data", data)
    if (data) {
      const newData = data.map((item: CountryTreeType) => ({
        x: item.title,
        y: item.count,
        id: item.id,
        fillColor: item.regions[0]?.color ?? "#ccc",
      }));
      setmappedData(newData);
    }
  }, [data]);

  return (
    <Box paddingY={0}>
      <Box paddingY={0}>
        <ul style={{ listStyle: "none", flexDirection: "row", display: "flex", justifyContent: "flex-start", gap: "10px", padding: 0,
         }}>
          <li style={{ backgroundColor: "#CC9900", color: "#fff", padding: "5px 10px", }}>Africa</li>
          <li style={{ backgroundColor: "#0BAD00", color: "#fff", padding: "5px 10px", }}>Americas</li>
          <li style={{ backgroundColor: "#FF6600", color: "#fff", padding: "5px 10px", }}>Asia</li>
          <li style={{ backgroundColor: "#99CCCC", color: "#fff", padding: "5px 10px", }}>Australia &amp; oceania</li>
          <li style={{ backgroundColor: "#3C51FF", color: "#fff", padding: "5px 10px", }}>Europe</li>
          <li style={{ backgroundColor: "#FFCC00", color: "#fff", padding: "5px 10px", }}>Middle East</li>
          <li style={{ backgroundColor: "#ffbbcc", color: "#fff", padding: "5px 10px", }}>Offshore/maritime</li>
        </ul>
      </Box>
      <Box paddingY={0}>
        {mappedData ? (
          <TreeChartWithColors loading={loading} data={[{ data: mappedData }]} colors={colors} setRef={setCanvas} />
        ) : (
          <div style={{ minHeight: "540px" }}>Loading...</div>
        )}
      </Box>
    </Box>
  );
};

export default NetworkTreeChartByCountry;
