import { Box, Breadcrumbs, Button, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "../components/ContentWrapper";
import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import allRoutes from "../constants/routes";
import { initialValues, setValues } from "../store/slices/chipsets";

const Allocations: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startSearch = () => {
    dispatch(setValues(initialValues));

    navigate(`${allRoutes.spectrum_assignment.path}/search`);
  };
  return (
    <>
      <Helmet title="Spectrum Assignment - GSA GAMBoD" />
      <PageHeading
        title={allRoutes.spectrum_assignment.label}
        icon={allRoutes.spectrum_assignment.icon}
        color={allRoutes.spectrum_assignment.color}
        section="spectrum"
      >
        <Breadcrumbs>
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Typography>Spectrum Assignment</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper body inset>
        <Box marginY={16} maxWidth={640}>
          <Typography variant="subtitle1">
            Global spectrum assignments for 3GPP mobile technologies filtered by region and country. Results are displayed graphically for
            each country or region selected.
          </Typography>
        </Box>
        <Box marginY={16}>
          {/* @ts-ignore */}
          <Button variant="contained" color="spectrum_assignment" sx={{ color: "white" }} onClick={startSearch}>
            Start New Search
          </Button>
        </Box>
      </ContentWrapper>
      <Divider />
      <SaveList sectionId={6} />
    </>
  );
};

export default Allocations;
