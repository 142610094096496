import { Box, Breadcrumbs, Button, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import allRoutes from "../constants/routes";
import { resetValues } from "../store/slices/devices";

const DevicesPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startSearch = () => {
    dispatch(resetValues());

    navigate(`${allRoutes.devices.path}/search`);
  };

  return (
    <>
      <Helmet title="Devices - GSA GAMBoD" />
      <PageHeading title={allRoutes.devices.label} icon={allRoutes.devices.icon} color={allRoutes.devices.color} section="devices">
        <Breadcrumbs>
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Typography>Devices</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper body inset>
        <Box marginY={16} maxWidth={640}>
          <Typography variant="subtitle1">
            The latest updates on mobile devices across a growing number of categories. Multiple search criteria and filtering of results to
            refine your searches. Graphical outputs for use in presentations and reports.
          </Typography>
        </Box>
        <Box marginY={16}>
          {/* @ts-ignore */}
          <Button variant="contained" color="devices" onClick={startSearch} data-testid="searchButton">
            Start New Search
          </Button>
        </Box>
      </ContentWrapper>
      <Divider />
      <SaveList sectionId={1} />
    </>
  );
};

export default DevicesPage;
