import React, { useState } from "react";
import { Box, Theme, Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { AllocationType } from "../../types";

const useTabStyles = makeStyles<Theme, { active?: boolean }>(() => ({
  root: {
    position: "absolute",
    height: "100%",
    display: "block",
    top: 0,
    opacity: ({ active }) => (active ? 1 : 0.8),
    borderStyle: "solid",
    borderWidth: ({ active }) => (active ? 4 : 0),
    borderLeftWidth: ({ active }) => (active ? 4 : 1),
    borderColor: "rgba(255, 255, 255, 0.75)",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
}));

interface AllocationsBarItemProps {
  title: string;
  left: number;
  width: number;
  isFuture?: boolean;
  active?: boolean;
  allocation?: AllocationType;
  color: string;
  onClick?: () => void;
}

const AllocationsBarItem: React.FC<AllocationsBarItemProps> = ({ title, left, width, active, color, onClick }) => {
  const tabStyles = useTabStyles({ active });
  const [open, setOpen] = useState(false);

  return (
    <Tooltip title={title} placement="top" open={active || open} arrow onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <Box
        component="span"
        bgcolor={color}
        left={`${left}%`}
        width={`${width}%`}
        zIndex={active ? 100 : 1}
        className={tabStyles.root}
        onMouseOut={() => setOpen(false)}
        onMouseOver={() => setOpen(true)}
        onClick={() => {
          onClick && onClick();
        }}
      />
    </Tooltip>
  );
};

export default AllocationsBarItem;
