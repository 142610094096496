import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import FilterSlider from "../FilterSlider";
import FilterListItem from "../FilterListItem";
import { downloadSpeeds } from "../../constants/downloadSpeeds";

export interface SpeedFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  value: number;
  disabled?: boolean;
  loading?: boolean;
  control: string;
  open?: boolean;
  icon?: React.ReactNode;
  setOpen: (value: boolean) => void;
  setControl: (value: string) => void;
  onChange: (value: number) => void;
}

const SpeedFilter: React.FC<SpeedFilterProps> = ({
  title,
  value,
  tooltip,
  control,
  disabled,
  loading,
  open,
  icon,
  onChange,
  setOpen,
  setControl,
}) => {
  const [valueCurrent, setValue] = useState(value);

  const min = Math.min(...downloadSpeeds);
  const max = Math.max(...downloadSpeeds);

  const getValue = (value: number) => `${value >= 1000 ? value / 1000 : value} ${value < 1000 ? "Mbps" : "Gbps"}`;

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <FilterListItem title={title} tooltip={tooltip} icon={icon} selected={0} open={open} setOpen={setOpen}>
      <FilterSlider
        value={valueCurrent}
        min={min}
        max={max}
        track={control === "above" ? "inverted" : control === "below" ? "normal" : false}
        marks={[
          {
            value: min,
            label: getValue(min),
          },
          {
            value: 500,
            label: getValue(500),
          },
          {
            value: 1000,
            label: getValue(1000),
          },
          {
            value: max,
            label: getValue(max),
          },
        ]}
        disabled={disabled || loading}
        onChange={(value: number | number[]) => {
          if (!Array.isArray(value)) {
            // We floor the download speed to the nearest ten as in the UI it only displays the nearest ten so to negate any potential
            // inconsitancies we floor it, so if you selected 1 gbps, the ui control will actually have set 1004gbps. We then change it
            // to 1000 to keep consitancies.
            const roundedDownValue = Math.floor(value / 10) * 10;
            setValue(roundedDownValue);
          }
        }}
        onBlur={() => {
          onChange(valueCurrent);
        }}
      />
      <Box padding={4}>
        <RadioGroup
          name="control"
          value={control}
          onChange={(_, value) => {
            setControl(value);
          }}
        >
          <FormControlLabel value="below" control={<Radio color="primary" />} label="Search less than" />
          <FormControlLabel value="exact" control={<Radio color="primary" />} label="Search equal to" />
          <FormControlLabel value="above" control={<Radio color="primary" />} label="Search greater than" />
          <FormControlLabel value="all" control={<Radio color="primary" />} label="Search all speeds" />
        </RadioGroup>
      </Box>
      <Button
        variant="outlined"
        size="small"
        disabled={loading || disabled}
        onClick={() => {
          setControl("all");
        }}
      >
        Reset
      </Button>
    </FilterListItem>
  );
};

export default SpeedFilter;
