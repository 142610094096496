import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { Checkbox, FormControlLabel, Button, Box, TextField, Typography, CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";
import { sanitize } from "../utils/sanitize";

import Layout from "../layouts/Auth";
import { signIn, signInFromCookie } from "../store/slices/auth";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";

const SignIn = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [validatingLogin, setValidatingLogin] = useState(false);
  const [cookieError, setCookieError] = useState(null);
  const now = new Date();
  const version = "v1." + (now.getMonth() + 1) + now.getDate() + "." + now.getHours() + now.getMinutes();

  useEffect(() => {
    const doIt = async () => {
      const cookies = new Cookies();
      for (var i in cookies.getAll()) {
        if (i.startsWith("wordpress_logged_in_")) {
          setValidatingLogin(true);
          setCookieError(null);
          try {
            const response = (await dispatch(signInFromCookie({ cookie: cookies.get(i) }))) as any;

            if (response.payload?.data) {
              navigate("/dashboard");
            } else {
              const message = response.payload?.error || "Something went wrong";
              setCookieError(message);
            }
          } finally {
            setValidatingLogin(false);
          }
        }
      }
    };

    doIt();
  });

  return (
    <Layout>
      <Helmet title="Sign In - GSA GAMBoD" />

      <Box width="100%" marginY={4}>
        <Box width="100%" marginY={8}>
          <Typography component="h2" variant="h2" align="center" gutterBottom title={version}>
            Welcome to GAMBoD
          </Typography>
        </Box>

        {validatingLogin ? (
          <>
            <Typography align="center">
              Authenticating user...
              <br />
              <br />
              <CircularProgress size={22} thickness={6} color="inherit" />
            </Typography>
          </>
        ) : (
          <>
            <Typography align="center" gutterBottom>
              GAMBoD is available only to qualified users, please see our membership options <a href="https://gsacom.com/join/">here</a>
            </Typography>
            <Typography align="center">Sign in to your account to continue.</Typography>
          </>
        )}
      </Box>

      {validatingLogin ? (
        <></>
      ) : (
        <Box width="100%" marginY={4}>
          <Formik
            initialValues={{
              username: "",
              password: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().min(2).max(255).required("Username is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              setCookieError(null);
              // @ts-ignore
              const response = (await dispatch(signIn({ email: values.username, password: values.password }))) as any;

              if (response.payload?.data) {
                new Cookies().set(response.payload?.data.cookieName, response.payload?.data.cookie, {
                  domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
                });
                navigate("/dashboard");
              } else {
                const message = response.payload?.error || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                {(errors.submit || cookieError) && (
                  <Box width="100%" paddingBottom={4}>
                    <Alert severity="warning">
                      {/*
                      //@ts-ignore */}
                      <span dangerouslySetInnerHTML={{ __html: sanitize(errors.submit) }} />
                      <span dangerouslySetInnerHTML={{ __html: sanitize(errors.submit) || cookieError || "" }} />
                    </Alert>
                  </Box>
                )}
                <Box width="100%" paddingBottom={4}>
                  <TextField
                    type="text"
                    name="username"
                    label="Username"
                    value={values.username}
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" paddingBottom={4}>
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
                <Box width="100%" paddingBottom={4}>
                  <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={22} thickness={6} color="inherit" />}
                >
                  {isSubmitting || validatingLogin ? "Loading..." : "Sign in"}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Layout>
  );
};

export default SignIn;
