import React from "react";
import { NavLink } from "react-router-dom";
import { TableContainer, Table, TableBody, Box, Link, Theme, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import TableRow from "./TableRow";
import TableHead from "./TableHead";

export interface TableCell {
  id: string;
  sortable: boolean;
  label: string;
  active?: boolean;
  link?: {
    urlBase: string;
    urlParam: string;
  };
  value: (row: any) => string;
  externalUrl?: (row: any) => string; //external Link href another site, opened in a new tab
  desc?: boolean;
  size: string;
}

export interface EnhancedTableProps {
  rows: any[];
  cellsMap: TableCell[];
  id: string;
  maxWidth?: number | string;
  dense: boolean;
  fontSize?: number;
  onOrder: (key: string) => void;
}

const EnhancedTable: React.FC<EnhancedTableProps> = ({ rows, cellsMap, maxWidth = "none", dense, id, fontSize = 1, onOrder }) => {
  const theme = useTheme();
  const useTableStyles = makeStyles<Theme, { maxWidth: number | string; fontSize: number }>(() => ({
    root: {
      maxWidth: ({ maxWidth }) => maxWidth,
      fontSize: ({ fontSize }) => (fontSize === 0 ? "0.7rem" : fontSize === 2 ? "0.9rem" : fontSize === 3 ? "1rem" : "0.8rem"),
    },
  }));

  const useBoxStyles = makeStyles(() => ({
    root: {
      [theme.breakpoints.down("sm")]: {
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          right: 0,
          top: 0,
          width: 48,
          height: "100%",
          background: "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));",
        },
      },
    },
  }));
  const tableStyles = useTableStyles({ maxWidth, fontSize });
  const boxStyles = useBoxStyles({ maxWidth, fontSize });

  return (
    <Box width="100%" id={id} position="relative" className={boxStyles.root}>
      <TableContainer>
        <Table size={dense ? "small" : "medium"} classes={tableStyles}>
          <TableHead cells={cellsMap} onOrder={onOrder} />
          <TableBody>
            {rows.map((row: any, index: any) => {
              const values = cellsMap.map(cell => {
                const value = cell.value(row);
                const externalUrl = cell.externalUrl?.(row);

                if (cell.link) {
                  return <Link href={`${cell.link.urlBase}${row[cell.link.urlParam]}`}>{value}</Link>;
                } else if (externalUrl) {
                  return (
                    <a href={externalUrl} target="_blank" rel="noreferrer">
                      {value}
                    </a>
                  );
                }

                return value;
              });

              return <TableRow key={index} row={values} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EnhancedTable;
