import { Box, Breadcrumbs, Divider, Grid, Typography } from "@mui/material";
import { Alert } from "@mui/lab";
import React from "react";
import { useSelector } from "react-redux";

import ContentWrapper from "../components/ContentWrapper";
import DashboardCard from "../components/DashboardCard";
import DashboardMetrics from "../components/DashboardMetrics";
import PageHeading from "../components/PageHeading";
import allRoutes from "../constants/routes";
import AllocationsSvg from "../icons/AllocationsSvg";
import ChipsetsSvg from "../icons/ChipsetsSvg";
import DevicesSvg from "../icons/DevicesSvg";
import NetworksSvg from "../icons/NetworksSvg";
import OperatorsSvg from "../icons/OperatorsSvg";
import { selectPermissions, selectUserDisplayName } from "../store/slices/auth";

const Dashboard: React.FC = () => {
  const displayName = useSelector(selectUserDisplayName);
  const hours = new Date().getHours();
  const message = hours < 12 ? "morning" : hours < 18 ? "afternoon" : "evening";
  //const permissions = useSelector(selectPermissions);
  const permissions = [
    "read",
    "read_gambod",
    "read_locked_pdf",
    "read_nts",
    "read_operator_profiles",
    "read_gambod_5g",
    "read_chipsets",
    "read_spectrum",
    "access_fwa_tool",
    "read_gambod_allocations",
    "read_gambod_chipsets",
    "read_gambod_dashboard",
    "read_gambod_devices",
    "read_gambod_my_searches",
    "read_gambod_operators",
    "read_gambod_private_networks",
    "read_gambod_public_networks",
  ];
  // console.log("permissions", permissions);

  return (
    <>
      <PageHeading title={`Good ${message}${displayName ? ` ${displayName}` : ""}, welcome back to GAMBoD`} inset>
        <Breadcrumbs aria-label="Breadcrumb">
          <Typography>Dashboard</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper>
        <DashboardMetrics />
        <Divider />
        <Box marginY={16}>
          <Alert severity="info">
            <Typography>
              GSA is constantly updating the GAMBoD databases with the latest industry data. Search criteria are expanded as new features
              and technologies or spectrum bands are added. If you have any feedback or suggestions for new areas of data collection please
              let us know in the feedback form.
            </Typography>
          </Alert>
        </Box>
        <Box marginTop={4} paddingBottom={4}>
          <Grid container spacing={4} data-testid="dashboardCards">
            {permissions.includes("read_gambod_devices") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<DevicesSvg />}
                  colorName="devices"
                  color={allRoutes.devices.color}
                  title={allRoutes.devices.label}
                  path={`${allRoutes.devices.path}/search`}
                  body="Search 4G and 5G Devices data. Feature descriptions including Category type. Spectrum bands supported."
                />
              </Grid>
            )}
            {permissions.includes("read_gambod_public_networks") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<NetworksSvg />}
                  colorName="publicNetworks"
                  color={allRoutes.publicNetworks.color}
                  title={allRoutes.publicNetworks.label}
                  path={`${allRoutes.publicNetworks.path}/search`}
                  body="Search by Country, Region, Network Technology 4G/5G and more."
                />
              </Grid>
            )}
            {permissions.includes("read_gambod_private_networks") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<NetworksSvg />}
                  colorName="privateNetworks"
                  color={allRoutes.privateNetworks.color}
                  title={allRoutes.privateNetworks.label}
                  path={`${allRoutes.privateNetworks.path}/search`}
                  body="Search by Region, Operator, Vertical, Network Technology 4G/5G, and more"
                />
              </Grid>
            )}
            {permissions.includes("read_gambod_chipsets") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<ChipsetsSvg />}
                  colorName="chipsets"
                  color={allRoutes.chipsets.color}
                  title={allRoutes.chipsets.label}
                  path={`${allRoutes.chipsets.path}/search`}
                  body="Chipsets from silicon vendors. Technologies supported. Features supported."
                />
              </Grid>
            )}
            {permissions.includes("read_spectrum") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<AllocationsSvg />}
                  colorName="spectrum_assignment"
                  color={allRoutes.spectrum_assignment.color}
                  title={allRoutes.spectrum_assignment.label}
                  path={`${allRoutes.spectrum_assignment.path}/search`}
                  body="Global Spectrum Assignments. Regional Breakdowns. Graphically displayed."
                />
              </Grid>
            )}
            {permissions.includes("read_gambod_operators") && (
              <Grid item xs={12} sm={6} md={4}>
                <DashboardCard
                  icon={<OperatorsSvg />}
                  colorName="operators"
                  color={allRoutes.operators.color}
                  title={allRoutes.operators.label}
                  path={`${allRoutes.operators.path}/search`}
                  body="Mobile Networks database. Search by Operator Name, list of Technologies and Spectrum."
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </ContentWrapper>
    </>
  );
};

export default Dashboard;
