import React from "react";
import { Box } from "@mui/material";

interface ContentWrapperProps {
  body?: boolean;
  bar?: boolean;
  inset?: boolean;
  children?: React.ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ body, bar, inset, children }) => (
  <Box
    width="100%"
    paddingLeft={[8, 8, 8, 16, inset ? 20 : 8]}
    paddingRight={[4, 8]}
    bgcolor={bar ? "#e9e9e9" : body ? "background.default" : "background.paper"}
  >
    {children}
  </Box>
);

export default ContentWrapper;
