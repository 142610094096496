import { createTheme, Theme } from "@mui/material";

import colors from "./colors";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";
import components from "./components";
const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: any) => augmentColor({ color: { main: mainColor } });

const defaultProps = {
  spacing: 2,
  breakpoints: breakpoints,
  overrides: overrides,
  props: props,
  typography: typography,
  shadows: shadows,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1000,
  },
};

const defaultPalette = {
  primary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.primary,
    contrastText: colors.white,
  },
  devices: createColor("#e7362d"),
  publicNetworks: createColor("#ee238c"),
  privateNetworks: createColor("#ee238c"),
  operators: createColor("#7035c0"),
  chipsets: createColor("#4daaf2"),
  spectrum_assignment: createColor("#24b654"),
  tertiary: colors.tertiary,
  gunmetal: colors.greys,
  bar1: colors.bar1,
  bar2: colors.bar2,
  bar3: colors.bar3,
  tonalOffset: 0.05,
};

export const lightVariant = createTheme({
  ...defaultProps,
  palette: {
    mode: "light",
    ...defaultPalette,
    background: {
      default: colors.white,
      paper: colors.background,
    },
  },
  components: {
    ...components,
  },
});

export const darkVariant = createTheme({
  ...defaultProps,
  palette: {
    ...defaultPalette,
    mode: "dark",
    background: {
      default: colors.greys[500],
      paper: colors.greys[500],
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(255, 255, 255, 0.1)",
  },
  components: {
    ...components,
  },
});

type VariantsType = {
  DEFAULT: Theme;
  LIGHT: Theme;
  DARK: Theme;
};

export const variants: VariantsType = {
  DEFAULT: lightVariant,
  LIGHT: lightVariant,
  DARK: darkVariant,
};
