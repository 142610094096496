import { SvgIcon } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const OperatorsSvg: React.FC = () => {
  const useIconStyles = makeStyles(() => ({
    root: {
      fontSize: "1.5rem",
    },
  }));
  const iconStyles = useIconStyles();

  return (
    <SvgIcon viewBox="0 0 33.4 31.38" classes={iconStyles}>
      <path
        d="M37.4,11.93l-.07-.07-.07.07-.55.55-.08.07.08.07a14.88,14.88,0,0,1,0,21.06l-.07.07.07.07.55.55.07.07.07-.07A15.76,15.76,0,0,0,42.1,23.2h0A16.07,16.07,0,0,0,37.4,11.93Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M37.38,34.58l-.84-.83.15-.14a14.79,14.79,0,0,0,0-20.92l-.15-.14.84-.83.14.14A16.2,16.2,0,0,1,42.2,23.2a15.8,15.8,0,0,1-4.68,11.24Zm-.55-.83.55.55h0A15.6,15.6,0,0,0,42,23.2,16,16,0,0,0,37.33,12h0l-.55.55a15,15,0,0,1,0,21.2Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M35.09,14.24,35,14.17l-.07.07-.55.55-.08.07.08.07a11.69,11.69,0,0,1,0,16.44l-.08.07.08.07.55.55.07.07.07-.07a12.6,12.6,0,0,0,0-17.82Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M35,32.27l-.84-.83.15-.14a11.59,11.59,0,0,0,0-16.3l-.15-.14L35,14l.14.14a12.7,12.7,0,0,1,0,18Zm-.55-.83L35,32a12.48,12.48,0,0,0,0-17.68h0l-.55.55a11.78,11.78,0,0,1,0,16.58Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M32.64,29.7l.07.07.07-.07a9.32,9.32,0,0,0,0-13.2l-.07-.07-.07.07-.56.55-.07.07.07.07a8.27,8.27,0,0,1,2.44,5.91A8.48,8.48,0,0,1,32.08,29l-.07.07.07.07Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M32.71,29.91l-.84-.83.14-.14a8.39,8.39,0,0,0,2.41-5.84A8.17,8.17,0,0,0,32,17.27l-.14-.15.84-.83.14.14a9.42,9.42,0,0,1,0,13.34Zm0-.28h0a9.22,9.22,0,0,0,0-13.06h0l-.55.55a8.43,8.43,0,0,1,2.46,6,8.6,8.6,0,0,1-2.46,6Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M30.33,27.39l.07.07.07-.07a6.07,6.07,0,0,0,1.79-4.29,6,6,0,0,0-1.79-4.29l-.07-.07-.07.07-.56.55-.07.07.07.07a5.12,5.12,0,0,1,0,7.2l-.07.07.07.07Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M30.4,27.6l-.84-.83.14-.14a5,5,0,0,0,0-7l-.14-.15.84-.83.14.14a6,6,0,0,1,1.81,4.36,6.13,6.13,0,0,1-1.81,4.36Zm0-.28h0a5.94,5.94,0,0,0,1.76-4.22,5.86,5.86,0,0,0-1.76-4.22h0l-.56.55a5.23,5.23,0,0,1,0,7.34Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M14.24,12.62l.07-.07-.07-.07-.55-.55-.07-.07-.07.07a15.8,15.8,0,0,0,0,22.39l.07.07.07-.07.55-.55.07-.07-.07-.07a14.88,14.88,0,0,1,0-21Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M13.62,34.53l-.14-.14a15.9,15.9,0,0,1,0-22.53l.14-.14.84.83-.15.14a14.79,14.79,0,0,0,0,20.87l.15.14Zm0-22.53h0a15.7,15.7,0,0,0,0,22.25l.55-.55a15,15,0,0,1,0-21.15Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M16.55,14.93l.08-.07-.08-.07L16,14.24l-.07-.07-.07.07a12.58,12.58,0,0,0,0,17.82l.07.07.07-.07.55-.55.08-.07-.08-.07a11.83,11.83,0,0,1-3.43-8.22A11.54,11.54,0,0,1,16.55,14.93Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M15.93,32.27l-.14-.14a12.67,12.67,0,0,1,0-18l.14-.14.84.83-.14.14a11.46,11.46,0,0,0-3.41,8.15,11.71,11.71,0,0,0,3.41,8.15l.14.14Zm0-18a12.48,12.48,0,0,0,0,17.68l.55-.55h0A11.94,11.94,0,0,1,13,23.15a11.69,11.69,0,0,1,3.46-8.29h0l-.55-.55Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M18.17,29.7l.07.07.07-.07.56-.55.07-.07L18.87,29a8.27,8.27,0,0,1-2.44-5.91,8.48,8.48,0,0,1,2.44-5.91l.07-.07-.08-.07-.55-.5-.07-.07-.07.07a9.24,9.24,0,0,0-2.74,6.6A9,9,0,0,0,18.17,29.7Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M18.24,29.91l-.14-.14a9.13,9.13,0,0,1-2.77-6.62,9.37,9.37,0,0,1,2.77-6.67l.14-.14.84.77-.14.15a8.39,8.39,0,0,0-2.41,5.84,8.21,8.21,0,0,0,2.41,5.84l.14.14Zm0-13.29a9.18,9.18,0,0,0-2.71,6.53,8.94,8.94,0,0,0,2.71,6.48l.55-.55a8.43,8.43,0,0,1-2.46-6,8.6,8.6,0,0,1,2.46-6h0Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M21.18,26.89l.07-.07-.07-.07a5.12,5.12,0,0,1,0-7.2l.07-.07-.07-.07-.56-.55-.07-.07-.07.07a6.09,6.09,0,0,0-1.79,4.29,6,6,0,0,0,1.79,4.29l.07.07.07-.07Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M20.55,27.65l-.14-.14a6.06,6.06,0,0,1-1.82-4.36,6.14,6.14,0,0,1,1.82-4.36l.14-.14.84.83-.14.15a5,5,0,0,0,0,7.05l.14.14Zm0-8.72a6,6,0,0,0-1.76,4.22,5.86,5.86,0,0,0,1.76,4.22h0l.55-.55a5.24,5.24,0,0,1,0-7.34Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M17.7,42.55l0,.1.1,0,.7.25.09,0,0-.1L20,39H30.93l1.43,3.9,0,.1.09,0,.7-.25.1,0,0-.1L26.9,25.2A2.63,2.63,0,0,0,28,23.15a2.51,2.51,0,0,0-2.52-2.51,2.5,2.5,0,0,0-1.42,4.56Zm8.43-18h0a1.3,1.3,0,0,1-.84.09h0a1.36,1.36,0,0,1-.41-.1,1.64,1.64,0,0,1-.94-1.41,1.56,1.56,0,1,1,3.11,0A1.64,1.64,0,0,1,26.13,24.57Zm-.12,1h0l1.88,5.07H23.06l1.88-5.07H25a1.51,1.51,0,0,0,.46.05h0a1.51,1.51,0,0,0,.46-.05H26Zm2.21,6.08,1,2.71H21.75l1-2.71Zm1.35,3.66,1,2.72H20.39l1-2.72Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
      <path
        d="M18.61,43.09l-1.08-.38,6.4-17.47a2.6,2.6,0,1,1,4.15-2.09A2.66,2.66,0,0,1,27,25.23l6.4,17.48-1.08.38-1.48-4H20.09ZM20,38.87H31l0,.07,1.44,3.9h0l.7-.25L26.78,25.16l.06,0a2.47,2.47,0,0,0,1.05-2,2.42,2.42,0,0,0-2.42-2.41,2.4,2.4,0,0,0-1.37,4.38l.07,0,0,.07L17.79,42.59l-.09,0,.79.29Zm10.75-.75H20.25l1.06-2.91h8.33Zm-10.16-.2h9.87L29.5,35.4h-8Zm8.8-3.47H21.61l1.05-2.91h5.63Zm-7.45-.2h7.17l-.91-2.51H22.8ZM28,30.73H22.91l2-5.27H25a2.39,2.39,0,0,0,.87,0h.15l0,.07Zm-4.84-.19h4.55l-1.81-4.87a1.87,1.87,0,0,1-.46,0,2,2,0,0,1-.47,0Zm2.42-5.75c-.12,0-.24,0-.35,0a1.34,1.34,0,0,1-.44-.1,1.77,1.77,0,0,1-1-1.51,1.66,1.66,0,1,1,3.31,0,1.74,1.74,0,0,1-.95,1.51h0A1.1,1.1,0,0,1,25.62,24.79Zm-.15-3.1A1.47,1.47,0,0,0,24,23.15a1.53,1.53,0,0,0,.89,1.32,1,1,0,0,0,.36.09h0a1.19,1.19,0,0,0,.78-.07h0a1.54,1.54,0,0,0,.85-1.33A1.48,1.48,0,0,0,25.47,21.69Z"
        transform="translate(-8.8 -11.72)"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default OperatorsSvg;
