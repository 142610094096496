import { ComponentsProps } from "@mui/material/styles";

const props: ComponentsProps = {
  /*   MuiButtonBase: {
    disableRipple: true,
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: "h6" },
  }, */
};

export default props;
