import { FilterList } from "@mui/icons-material";
import { Box, Button, Divider, ThemeProvider } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { toggleFiltersOpen } from "../../store/slices/ui";
import { variants } from "../../theme/variants";

const FilterNav: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={variants.DARK}>
      <Box bgcolor="grey.500" sx={{ display: { xs: "block", lg: "none" } }}>
        <Divider />
        <Box textAlign="right" paddingX={4}>
          <Button endIcon={<FilterList />} onClick={() => dispatch(toggleFiltersOpen())}>
            Filters
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FilterNav;
