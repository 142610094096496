import React from "react";
import { useDispatch } from "react-redux";
import { Drawer, DrawerProps, useMediaQuery, useTheme, ClickAwayListener } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { setFiltersOpen } from "../../store/slices/ui";

const Sidebar: React.FC<DrawerProps> = ({ children, open, ...rest }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  const useDrawerStyles = makeStyles(() => {
    return {
      paperAnchorDockedLeft: {
        borderWidth: 0,
      },
      paper: {
        backgroundColor: "black",
        borderRight: "0 !important",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: 340,
        },
      },
    };
  });
  const drawerStyles = useDrawerStyles();

  return (
    <>
      <Drawer open={open} variant={mobile ? "temporary" : "permanent"} classes={drawerStyles} {...rest}>
        <ClickAwayListener
          onClickAway={() => {
            dispatch(setFiltersOpen(false));
          }}
        >
          <>{children}</>
        </ClickAwayListener>
      </Drawer>
    </>
  );
};

export default Sidebar;
