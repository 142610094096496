export const navigation = [
  {
    label: "Home",
    url: "https://gsacom.com/",
  },
  {
    label: "5G",
    url: "https://gsacom.com/technology/5g/",
  },
  {
    label: "Webinars",
    url: "https://gsacom.com/webinars/",
  },
  {
    label: "Reports",
    url: "https://gsacom.com/reports/",
  },
];
