import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useGetVerticalsQuery } from "../../store/apis/search";
import FilterListItem from "../FilterListItem";

export interface VerticalFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  value: number[];
  placeholder?: string;
  open?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  setOpen: (value: boolean) => void;
  onChange: (value: any[]) => void;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const VetticalsFilter: React.FC<VerticalFilterProps> = ({
  title = "verticals",
  name = "verticals-filter",
  tooltip,
  value = [],
  open,
  icon,
  setOpen,
  onChange,
}) => {
  const classes = useStyles();

  const { data: verticalsOptions = [], isLoading: isVerticalsLoading } = useGetVerticalsQuery();

  const [verticalValue, setverticalValue] = React.useState(0);
  const [subVerticalValue, setsubVerticalValue] = React.useState(0);

  useEffect(() => {
    let foundVertical = verticalsOptions.find(option => option.id === value[0]);
    if (!foundVertical) {
      //if not found in verticals look in subverticals
      foundVertical = verticalsOptions.find(vertical => {
        return vertical.subvertical.some(item => {
          return item.id === value[0];
        });
      });
    }
    const foundVerticalId = foundVertical?.id ?? 0;
    setverticalValue(foundVerticalId);
    // if its subvertical
    if (foundVertical?.id !== value[0]) {
      setsubVerticalValue(value[0] ?? 0);
    }
  }, [verticalsOptions, value]);

  const handleChange = (event: any) => {
    setverticalValue(event.target.value);
    setsubVerticalValue(0);
    onChange([event.target.value]);
  };

  const handleChange2 = (event: any) => {
    setsubVerticalValue(event.target.value);
    onChange([event.target.value]);
  };
  const handleReset = (event: any) => {
    setverticalValue(0);
    setsubVerticalValue(0);
    onChange([]);
  };

  const listSubverticals = () => {
    const selectedVertical = verticalsOptions.find(option => option.id === verticalValue);
    const subverticals = selectedVertical?.subvertical;
    return subverticals ?? [];
  };

  return (
    <>
      <FilterListItem title={title} icon={icon} open={open} tooltip={tooltip} setOpen={setOpen} selected={value.flat().length}>
        {/* {value[0]} / {verticalValue} / {subVerticalValue} */}
        <Box marginBottom={4} id="verticals-filter">
          <FormControl variant="filled" className={classes.formControl} disabled={isVerticalsLoading} fullWidth>
            <InputLabel id="vertical">Vertical</InputLabel>
            <Select labelId="vertical" id={`${name}-parent`} value={verticalValue} onChange={handleChange} data-testid="verticalsDropdown">
              <MenuItem key={`m1-`} value={""}>
                ---
              </MenuItem>
              {verticalsOptions.map(option => (
                <MenuItem key={`vertical${option.id}`} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {value[0] && listSubverticals()?.length > 0 && (
            <>
              <FormControl variant="filled" className={classes.formControl} fullWidth style={{ margin: "10px 0px 10px 0px" }}>
                <InputLabel id="subvertical">Subvertical</InputLabel>
                <Select labelId="subvertical" id={`${name}-parent`} value={subVerticalValue} onChange={handleChange2}>
                  <MenuItem key={`m2-`} value={""}>
                    ---
                  </MenuItem>
                  {listSubverticals()?.map(option => (
                    <MenuItem key={`subvertical${option.id}`} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Grid container spacing={2} marginTop={5}>
            <Grid item>
              <Button id={`${name}-clear`} variant="outlined" size="small" onClick={handleReset}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FilterListItem>
    </>
  );
};

export default VetticalsFilter;
