import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStateType } from "..";
import { RegionType } from "../../types";
import { PrivateNetworksValuesType } from "../../utils";
import countries from '../../mocks/countries';

export const initialValues: PrivateNetworksValuesType = {
  technologies: [],
  features: [],
  customer_status: [],
  statuses: [],
  featuresControl: true,
  ituRegions: [],
  regions: [],
  childRegions: [],
  countries: [],
  frequencyBands: [],
  frequencyBandControl: true,
  ue: [],
  downloadSpeed: 1600,
  downloadSpeedControl: "all",
  publicStatus: 2,
  startDate: null,
  endDate: null,
  spectrumBands: [],
  satVendors: [],
  satelitentn: [],
  switchoffTechs: null,
  verticals: [],
  subVerticals: [],
};

const initialRegion: RegionType = {
  parent_id: 0,
  parent_title: "",
  title: "",
  id: 0,
};

const privateNetworksSlice = createSlice({
  name: "privateNetworks",
  initialState: {
    values: initialValues,
    lastSaved: initialValues,
    rowsPerPage: 20,
    page: 1,
    sortBy: "operator_title",
    sortDesc: false,
    lastPage: "blank",
    allSubRegions: [initialRegion], // Array of RegionType
    filteredSubRegions: [initialRegion], // Array of RegionType
  },
  reducers: {
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
      state.page = 1;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setValues: (state, { payload }: PayloadAction<PrivateNetworksValuesType>) => {
      state.values = payload;
      state.page = 1;
    },
    setVertical: (state, { payload }: PayloadAction<number[]>) => {
      state.values = {...state.values, verticals: payload};
      state.page = 1;
    },
    setLastPage: (state, { payload }: PayloadAction<string>) => {
      state.lastPage = payload;
      state.page = 1;
    },
    setSavedValues: (state, { payload }: PayloadAction<PrivateNetworksValuesType>) => {
      state.lastSaved = payload;
    },
    setAllSubRegions: (state, { payload }: PayloadAction<RegionType[]>) => {
      state.allSubRegions = payload;
    },
    setSubRegions: (state, { payload }: PayloadAction<number[]>) => {
      state.filteredSubRegions =
        payload.length === 0 ? state.allSubRegions : state.allSubRegions.filter(region => payload.indexOf(region.parent_id) !== -1); //region.parent_id === payload);
    },
    setCountries: (state, { payload }: PayloadAction<number[]>) => {
      // console.log(payload);
      state.values = {...state.values, countries: payload};
    },
    resetValues: state => {
      state.page = 1;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      if (state.sortBy.valueOf() !== payload) {
        state.sortBy = payload;
        state.sortDesc = false;
        state.page = 1;
      } else {
        state.sortDesc = !state.sortDesc.valueOf();
      }
    },
  },
});

export const { setRowsPerPage, setPage, setValues, setVertical, resetValues, setSortBy, setSavedValues, setLastPage, setSubRegions, setCountries, setAllSubRegions } =
  privateNetworksSlice.actions;

export const selectPrivateNetworksSlice = (state: AppStateType) => state.privateNetworks;

export default privateNetworksSlice.reducer;
