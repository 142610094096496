import React from "react";
import { Box, Button, FormControlLabel, Grid, Radio, Link, RadioGroup } from "@mui/material";

import { FrequencyBandType } from "../../types";
import FilterListItem from "../FilterListItem";
import FilterAutocomplete from "../FilterAutocomplete";

import { NavLink, useLocation } from "react-router-dom";
import ExternalLinks from "../ExternalLinks";

export interface FrequenciesFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  value: FrequencyBandType[][];
  options: FrequencyBandType[][];
  disabled?: boolean;
  limitTags?: number;
  placeholder?: string;
  orValue?: boolean;
  open?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  setOr?: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  onChange: (value: FrequencyBandType[][]) => void;
}

const FrequenciesFilter: React.FC<FrequenciesFilterProps> = ({
  title = "Frequencies",
  name = "frequencies-filter",
  tooltip,
  value = [[], [], []],
  options = [[], [], []],
  disabled,
  loading,
  placeholder = "Search",
  limitTags = 4,
  open,
  icon,
  orValue,
  setOr,
  setOpen,
  onChange,
}) => {
  const [low, mid, high] = value;
  const [lowOptions, midOptions, highOptions] = options;

  const { pathname } = useLocation();

  return (
    <FilterListItem title={title} icon={icon} open={open} tooltip={tooltip} setOpen={setOpen} selected={value.flat().length}>
      <Box marginBottom={4}>
        <FilterAutocomplete
          name={`${name}-low-band`}
          label="Low band"
          placeholder={loading ? "Loading..." : placeholder}
          value={low}
          limitTags={limitTags}
          disabled={disabled || !lowOptions.length}
          options={lowOptions}
          onChange={values => {
            onChange([values, mid, high]);
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <Button
              id={`${name}-clear`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([[], mid, high]);
              }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button
              id={`${name}-select-all`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([lowOptions, mid, high]);
              }}
            >
              Select all
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom={4}>
        <FilterAutocomplete
          name={`${name}-mid-band`}
          label="Mid band"
          placeholder={loading ? "Loading..." : placeholder}
          value={mid}
          disabled={disabled || !midOptions.length}
          limitTags={limitTags}
          options={midOptions}
          onChange={values => {
            onChange([low, values, high]);
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <Button
              id={`${name}-clear`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([low, [], high]);
              }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button
              id={`${name}-select-all`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([low, midOptions, high]);
              }}
            >
              Select all
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom={4}>
        <FilterAutocomplete
          name={`${name}-high-band`}
          label="High band"
          placeholder={loading ? "Loading..." : placeholder}
          value={high}
          disabled={disabled || !highOptions.length}
          limitTags={limitTags}
          options={highOptions}
          onChange={values => {
            onChange([low, mid, values]);
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <Button
              id={`${name}-clear`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([low, mid, []]);
              }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button
              id={`${name}-select-all`}
              variant="outlined"
              size="small"
              onClick={() => {
                onChange([low, mid, highOptions]);
              }}
            >
              Select all
            </Button>
          </Grid>
        </Grid>
      </Box>
      {setOr && (
        <Box paddingX={4}>
          <RadioGroup
            name="control"
            value={orValue ? 1 : 0}
            onChange={(_, value) => {
              setOr(!!parseInt(value));
            }}
          >
            <FormControlLabel value={0} disabled={disabled} control={<Radio color="primary" />} label="Band control AND" />
            <FormControlLabel value={1} disabled={disabled} control={<Radio color="primary" />} label="Band control OR" />
          </RadioGroup>
          <ExternalLinks />
        </Box>
      )}
    </FilterListItem>
  );
};

export default FrequenciesFilter;
