export const commercialOptions = [
  {
    id: 0,
    title: "Unknown",
    value: 0,
  },
  {
    id: 1,
    title: "Commercial",
    value: 1,
  },
  {
    id: 2,
    title: "Pre-commercial",
    value: 2,
  },
];
