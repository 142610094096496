import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStateType } from "../";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    dense: false,
    mobileNavOpen: false,
    filtersOpen: false,
    fontSize: 1,
    exportFields: [],
    devices: {
      technologies: true,
      dates: false,
      vendors: false,
      formFactors: false,
      fallbacks: false,
      frequencyBands: false,
      supports: false,
      chipsets: false,
      commercial: false,
    },
    networks: {
      dates: false,
      technologies: false,
      customer_status: false,
      features: false,
      statuses: false,
      ituRegions: false,
      spectrumBands: false,
      satVendors: false,
      regions: false,
      childRegions: false,
      countries: false,
      frequencyBands: false,
      downloadSpeed: false,
      ueSpeed: false,
      ue: false,
      switchoffTechs: false,
      satelitentn: false,
      publicStatus: true,
      verticals: false,
      subVerticals: false,
    },
    chipsets: {
      regions: false,
      chipsetVendors: false,
      chipsetTypes: false,
      supports: false,
      technologies: false,
      ue: false,
      commercial: false,
    },
    operators: {
      search: true,
      regions: false,
      childRegions: false,
      countries: false,
      publicStatus: false,
      dates: false,
      switchoffTechs: false,
      upgradeTechs: false,
    },
    allocations: {
      ituRegions: true,
      regions: false,
      childRegions: false,
      countries: false,
      frequencyBand1: false,
      frequencyBand2: false,
      frequencyBand3: false,
      frequencyBand4: false,
    },
  },
  reducers: {
    setDevicesFilters: (state, { payload }: PayloadAction<any>) => {
      state.devices = payload;
    },
    closeDevicesFilters: state => {
      state.devices = {
        technologies: false,
        dates: false,
        vendors: false,
        formFactors: false,
        fallbacks: false,
        frequencyBands: false,
        supports: false,
        chipsets: false,
        commercial: false,
      };
    },
    setNetworksFilters: (state, { payload }: PayloadAction<any>) => {
      state.networks = payload;
    },
    closeNetworksFilters: state => {
      state.networks = {
        dates: false,
        technologies: false,
        customer_status: false,
        features: false,
        statuses: false,
        ituRegions: false,
        spectrumBands: false,
        satVendors: false,
        regions: false,
        childRegions: false,
        countries: false,
        frequencyBands: false,
        downloadSpeed: false,
        ueSpeed: false,
        ue: false,
        publicStatus: false,
        switchoffTechs: false,
        satelitentn: false,
        verticals: false,
        subVerticals: false,
      };
    },
    setChipsetsFilters: (state, { payload }: PayloadAction<any>) => {
      state.chipsets = payload;
    },
    closeChipsetsFilters: state => {
      state.chipsets = {
        regions: false,
        chipsetVendors: false,
        chipsetTypes: false,
        supports: false,
        technologies: false,
        ue: false,
        commercial: false,
      };
    },
    setOperatorsFilters: (state, { payload }: PayloadAction<any>) => {
      state.operators = payload;
    },
    closeOperatorsFilters: state => {
      state.operators = {
        search: false,
        regions: false,
        childRegions: false,
        countries: false,
        publicStatus: false,
        dates: false,
        switchoffTechs: false,
        upgradeTechs: false,
      };
    },
    setAllocationsFilters: (state, { payload }: PayloadAction<any>) => {
      state.allocations = payload;
    },
    closeAllocationsFilters: state => {
      state.allocations = {
        ituRegions: false,
        regions: false,
        childRegions: false,
        countries: false,
        frequencyBand1: false,
        frequencyBand2: false,
        frequencyBand3: false,
        frequencyBand4: false,
      };
    },
    toggleMobileNavOpen: state => {
      state.mobileNavOpen = !state.mobileNavOpen;
    },
    setMobileNavOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.mobileNavOpen = payload;
    },
    toggleFiltersOpen: state => {
      state.filtersOpen = !state.filtersOpen;
    },
    setFiltersOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersOpen = payload;
    },
    setDense: (state, { payload }: PayloadAction<boolean>) => {
      state.dense = payload;
    },
    setFontSize: (state, { payload }: PayloadAction<number>) => {
      state.fontSize = payload;
    },
    setExportFields: (state, { payload }: PayloadAction<any>) => {
      state.exportFields = payload;
    },
  },
});

export const {
  setDense,
  setFontSize,
  setExportFields,
  closeDevicesFilters,
  setDevicesFilters,
  closeNetworksFilters,
  setNetworksFilters,
  closeChipsetsFilters,
  setChipsetsFilters,
  closeOperatorsFilters,
  setOperatorsFilters,
  closeAllocationsFilters,
  setAllocationsFilters,
  setMobileNavOpen,
  toggleMobileNavOpen,
  setFiltersOpen,
  toggleFiltersOpen,
} = uiSlice.actions;

export const selectUiState = (state: AppStateType) => state.ui;

export default uiSlice.reducer;
