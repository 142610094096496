const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export default breakpoints;

/*
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    // md: 900, //960,
    lg: 1200, //1280,
    xl: 1536, //1440, //
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
*/
