import React from "react";
import { Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Save } from "@mui/icons-material";

export interface SaveSearchProps {
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const SaveSearch: React.FC<SaveSearchProps> = ({ loading, disabled, onClick }) => {
  const useButtonStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));
  const buttonStyles = useButtonStyles();

  return (
    <Button
      classes={buttonStyles}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      startIcon={loading ? <CircularProgress size={20} thickness={6} /> : <Save />}
    >
      Save Search
    </Button>
  );
};

export default SaveSearch;
