import React from "react";
import { Theme, Avatar } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useAvatarStyles = makeStyles<Theme, { color?: string }>({
  root: {
    backgroundColor: ({ color }) => color || "#cccccc",
    borderColor: "#cccccc",
    borderWidth: 1,
    borderStyle: "solid",
  },
});

export interface AppAvatarProps {
  color?: string;
  children?: React.ReactNode;
}

const AppAvatar: React.FC<AppAvatarProps> = ({ children, color }) => {
  return <Avatar sx={{ bgcolor: color }}>{children}</Avatar>;
};

export default AppAvatar;
