import React from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

import "../../vendor/perfect-scrollbar.css";

export interface FilterListProps {
  loading?: boolean;
  disabled?: boolean;
  canSearch?: boolean;
  canSave?: boolean;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onReset?: (event: React.FormEvent<HTMLFormElement>) => void;
  onSave?: () => void;
  children?: React.ReactNode;
}

const FilterList: React.FC<FilterListProps> = ({ loading, disabled, canSearch, canSave, children, onSubmit, onReset, onSave }) => (
  <Box component="form" height="100%" display="flex" flexDirection="column" onSubmit={onSubmit} onReset={onReset}>
    <PerfectScrollbar>{children}</PerfectScrollbar>
    <Divider />
    <Box padding={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            disabled={loading || disabled || !canSearch}
            type="submit"
            data-testid="runSearch"
          >
            Run search
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" size="large" fullWidth disabled={loading || disabled || !canSave} onClick={onSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" size="large" fullWidth type="reset" disabled={disabled}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default FilterList;
