import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography, Grid, Card, CardContent, IconButton, CardActionArea, Divider, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

import { AllocationsCountryType, AllocationType } from "../../types";
import AllocationsBar, { AllocationsListItemCellType } from "../AllocationsBar";
import { monthNames } from "../../constants";
import { ComponentsProps, useTheme } from "@mui/material/styles";

interface AllocationsListItemProps {
  data: AllocationsCountryType;
  min: number;
  max: number;
  index?: number;
  showCurrent?: boolean;
  showFuture?: boolean;
  allocations: AllocationType[];
}

const AllocationsListItem: React.FC<AllocationsListItemProps> = ({
  data,
  min,
  max,
  showCurrent = true,
  showFuture = true,
  allocations = [],
}) => {
  const theme = useTheme();
  const useIconStyles = makeStyles(() => ({
    root: {
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(24),
        height: theme.spacing(24),
      },
    },
  }));

  const iconStyles = useIconStyles();
  const [current, setCurrent] = useState(0);
  const past = allocations.filter(a => a.future < 1 && showCurrent);
  const future = allocations.filter(a => a.future > 0 && showFuture);

  let marker = min;

  const cellsPast: AllocationsListItemCellType[] = [];

  past.forEach((allocation, index) => {
    const start = Math.max(allocation.band_start || min, min);
    const end = Math.min(allocation.band_end || max, max);

    if (marker < start) {
      cellsPast.push({
        start: marker,
        end: start,
        title: "No data",
      });
    }

    cellsPast.push({
      start,
      end,
      title: allocation.operators?.length ? allocation.operators.map(operator => operator.title).join(", ") : "--",
      label: allocation.operators?.length ? (
        <>
          {allocation.operators.map((operator, index, arr) => (
            <React.Fragment key={index}>
              <Link href={`/operators/operator/${operator.id}`}>{operator.title}</Link>
              {index < arr.length - 1 && <>, </>}
            </React.Fragment>
          ))}
        </>
      ) : undefined,
      data: allocation,
    });

    marker = end;

    if (end < max && index === past.length - 1) {
      cellsPast.push({
        start: end,
        end: max,
        title: "No data",
      });
    }
  }, []);

  marker = min;

  const cellsFuture: AllocationsListItemCellType[] = [];

  future.forEach((allocation, index) => {
    const start = Math.max(allocation.band_start || min, min);
    const end = Math.min(allocation.band_end || max, max);

    if (marker < start) {
      cellsFuture.push({
        start: marker,
        end: start,
        title: "No data",
      });
    }

    cellsFuture.push({
      start,
      end,
      title: allocation.operators?.length ? allocation.operators.map(operator => operator.title).join(", ") : "--",
      label: allocation.operators?.length ? (
        <>
          {allocation.operators.map((operator, index, arr) => (
            <React.Fragment key={index}>
              <Link href={`/operators/operator/${operator.id}`}>{operator.title}</Link>
              {index < arr.length - 1 && <>, </>}
            </React.Fragment>
          ))}
        </>
      ) : undefined,
      data: allocation,
    });

    marker = end;

    if (end < max && index === future.length - 1) {
      cellsFuture.push({
        start: end,
        end: max,
        title: "No data",
      });
    }
  }, []);

  const cells = [...cellsPast, ...cellsFuture];
  const cell = cells[current];
  const total = cells.length;

  return (
    <>
      {data && (
        <>
          <Box paddingY={8} width="100%">
            <Typography variant="h4">{data.title}</Typography>
            {total > 0 ? (
              <Box paddingTop={12} width="100%">
                {showCurrent && <AllocationsBar data={cellsPast} current={current} min={min} max={max} setCurrent={setCurrent} />}
                {showFuture && (
                  <AllocationsBar
                    data={cellsFuture}
                    current={current}
                    min={min}
                    max={max}
                    setCurrent={setCurrent}
                    startIndex={cellsPast.length}
                    isFuture
                  />
                )}
                {cell && (
                  <Grid container justifyContent="space-evenly" alignItems="center">
                    <Grid item>
                      <IconButton
                        classes={iconStyles}
                        disabled={total < 2}
                        onClick={() => {
                          setCurrent(state => (state + total - 1) % total);
                        }}
                        size="large"
                      >
                        <ArrowBack />
                      </IconButton>
                    </Grid>
                    <Grid item xs={8} zeroMinWidth>
                      <Card>
                        <CardActionArea>
                          <CardContent>
                            <Typography variant="h5" gutterBottom>
                              Assignment Details
                            </Typography>
                            <Box>
                              <Grid container spacing={1}>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography align="right">
                                    <strong>Operators:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography>{cell.label || cell.title}</Typography>
                                </Grid>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography align="right">
                                    <strong>Country:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography>{cell.data?.country_title}</Typography>
                                </Grid>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography align="right">
                                    <strong>Frequency range:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} zeroMinWidth>
                                  <Typography>
                                    {cell.start} MHz - {cell.end} MHz
                                  </Typography>
                                </Grid>
                                {!!cell.data && (
                                  <>
                                    {cell.data.geographical_title && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Geography range:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data.geographical_title}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Assigned:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data?.assigned === "Y" ? "Yes" : "No"}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data?.usages && cell.data.usages.length > 0 && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Usage:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data.usages.map(usage => usage.title).join(", ")}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data?.format_title && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Spectrum Format:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data.format_title}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data?.award_year && parseInt(cell.data.award_year) > 0 && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Awarded:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>
                                            {cell.data.award_month && parseInt(cell.data.award_month) > 0
                                              ? monthNames[parseInt(cell.data.award_month) - 1]
                                              : ""}{" "}
                                            {cell.data.award_year}
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data?.plan_notes && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Notes:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data.plan_notes}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                    {cell.data?.assignment_title && (
                                      <>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography align="right">
                                            <strong>Assignment:</strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} zeroMinWidth>
                                          <Typography>{cell.data.assignment_title}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item>
                      <IconButton
                        classes={iconStyles}
                        disabled={total < 2}
                        onClick={() => {
                          setCurrent(state => (state + 1) % total);
                        }}
                        size="large"
                      >
                        <ArrowForward />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
            ) : (
              <Box paddingTop={4} width="100%">
                <Typography>No data.</Typography>
              </Box>
            )}
          </Box>
          <Divider />
        </>
      )}
    </>
  );
};

export default AllocationsListItem;
