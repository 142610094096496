import React, { useState } from "react";
import { Button, Grid, IconButton, Typography, Chip, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";

import { sectionColors } from "../../constants/routes";

export interface SaveItemProps {
  title: string;
  category?: string;
  description?: string;
  parameters?: any;
  date: string;
  sectionId?: number;
  loading?: boolean;
  onDelete?: () => void;
  onRun?: () => void;
  onRename?: () => void;
}

const cleanUpParameter = (thisKey: any) => {
  if (thisKey.indexOf("_id") > -1) {
    thisKey = thisKey.substring(0, thisKey.length - 3);
  }
  return thisKey.replaceAll("_", " ");
};
const SearchParamItem: React.FC<any> = ({ name, searchValue, index, lastItem }) => {
  return (
    <>
      {index > 0 && <>{lastItem ? " and " : ", "}</>}
      <strong title={JSON.stringify(searchValue)}>{cleanUpParameter(name)}</strong>
    </>
  );
};

const formatDate = (myDate: any) => {
  const dateObject = new Date(myDate);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const mins = dateObject.getUTCMinutes();
  const time = hours + ":" + (mins > 9 ? mins : "0" + mins);
  return day + "/" + month + "/" + year + " " + time;
};

const SaveItem: React.FC<SaveItemProps> = ({ title, description, parameters, date, sectionId = 1, loading, onDelete, onRun, onRename }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [cursorCoords, setcursorCoords] = useState({ x: 0, y: 0 });
  const mouseMove = (evt: any) => {
    const pos = evt.currentTarget.getBoundingClientRect();
    setcursorCoords({
      x: evt.clientX - pos.left,
      y: evt.clientY - pos.top,
    });
  };
  return (
    <div
      //@ts-ignore
      onMouseMove={(ev: Event) => mouseMove(ev)}
    >
      <Grid container alignItems="center" spacing={4} wrap="nowrap">
        <Grid item xs zeroMinWidth onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <Typography noWrap>
            <span>{title}</span>
          </Typography>
        </Grid>
        <Grid item xs zeroMinWidth onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <Typography noWrap>&nbsp; {`${formatDate(date)}`}</Typography>
        </Grid>
        <Grid item zeroMinWidth>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item zeroMinWidth>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => onRename && onRename()}
                style={{ color: sectionColors[sectionId - 1], borderColor: sectionColors[sectionId - 1] }}
              >
                Rename
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onRun && onRun()}
                style={{ backgroundColor: sectionColors[sectionId - 1] }}
              >
                Re-Run
              </Button>
            </Grid>
            <Grid item zeroMinWidth>
              <IconButton onClick={() => onDelete && onDelete()} size="large">
                {loading ? <CircularProgress size={20} thickness={6} /> : <Close />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showTooltip && (
        <div
          id="tooltip"
          style={{
            position: "absolute",
            zIndex: 900000000,
            left: `${cursorCoords.x + 160}px`,
            background: "#eee",
            borderRadius: "5px",
            padding: "8px 16px",
            textAlign: "center",
            minWidth: "140px",
            boxShadow: "1px 1px 5px #efefef",
          }}
        >
          <h4 style={{ margin: "4px 0px 8px" }}>Searched by:</h4>
          <Typography noWrap style={{ margin: "4px 0px", whiteSpace: "pre-line", textAlign: "left" }}>
            {description}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SaveItem;
