export const privateOptions = [
  {
    id: 1,
    title: "Public",
    value: 1,
  },
  {
    id: 2,
    title: "Private",
    value: 2,
  },
];
