import { Box } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../constants";
import { useGetYearlyGrowthQuery } from "../../store/apis/search";
import { PrivateNetworksValuesType } from "../../utils";
import AreaChart from "../AreaChart";

interface NetworksAreaChartProps {
  filters: PrivateNetworksValuesType;
  loading?: boolean;
}

const NetworksAreaChart: React.FC<NetworksAreaChartProps> = ({ filters, loading }) => {
  const [canvas, setCanvas] = useState<any>(null);
  const { data } = useGetYearlyGrowthQuery({ values: filters });

  return (
    <Box paddingY={4}>
      {data ? (
        <AreaChart loading={loading} data={data} colors={colors} setRef={setCanvas} />
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default NetworksAreaChart;
