import React from "react";
import { Box, Slider } from "@mui/material";

export interface FilterSliderProps {
  value: number | number[];
  marks?: any[];
  min?: number;
  max?: number;
  name?: string;
  disabled?: boolean;
  track?: false | "normal" | "inverted" | undefined;
  onChange?: (value: number | number[]) => void;
  onBlur?: () => void;
}

const FilterSlider: React.FC<FilterSliderProps> = ({
  value,
  min = 0,
  max = 1000,
  name = "speed-range",
  disabled,
  track = "normal",
  marks = [],
  onChange,
  onBlur,
}) => {
  const valuetext = (value: number) => `${Math.floor(value / 10) / 100}`;

  return (
    <Box paddingTop={8} paddingX={6}>
      <Slider
        track={track}
        value={value}
        min={min}
        max={max}
        step={1}
        name={name}
        marks={marks}
        disabled={disabled}
        valueLabelDisplay={disabled ? "off" : "on"}
        valueLabelFormat={valuetext}
        getAriaLabel={valuetext}
        getAriaValueText={valuetext}
        aria-labelledby="slider"
        onChange={(_, value) => onChange && onChange(value)}
        onChangeCommitted={() => onBlur && onBlur()}
      />
    </Box>
  );
};

export default FilterSlider;
