import { Box, Breadcrumbs, Button, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import allRoutes from "../constants/routes";
import { initialValues, setValues } from "../store/slices/chipsets";

const Chipsets: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startSearch = () => {
    dispatch(setValues(initialValues));

    navigate(`${allRoutes.chipsets.path}/search`);
  };

  return (
    <>
      <Helmet title="Chipsets - GSA GAMBoD" />
      <PageHeading title={allRoutes.chipsets.label} icon={allRoutes.chipsets.icon} color={allRoutes.chipsets.color} section="chipsets">
        <Breadcrumbs>
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Typography>Chipsets</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper body inset>
        <Box marginY={16} maxWidth={640}>
          <Typography variant="subtitle1">
            The latest updates on mobile device chipsets. Multiple search criteria and filtering of results to refine your searches.
            Graphical outputs for use in presentations and reports.
          </Typography>
        </Box>
        <Box marginY={16}>
          {/* @ts-ignore */}
          <Button variant="contained" color="chipsets" sx={{ color: "white" }} onClick={startSearch} data-testid="searchButton">
            Start New Search
          </Button>
        </Box>
      </ContentWrapper>
      <Divider />
      <SaveList sectionId={5} />
    </>
  );
};

export default Chipsets;
