import { Box } from "@mui/material";
import React from "react";
//import dataObject from "./static-data";
import { CountryDataType } from "./HeatMap";

const Table: React.FC<{ data: any }> = ({ data = [] }) => {
  const { meta = {}, data: dataObject = {} } = data;
  let countriesWithScore = [];
  let countries: any[] = [];
  let technologies = [];
  if (dataObject.length > 0) {
    countriesWithScore = dataObject.toSorted((a: CountryDataType, b: CountryDataType) => b.score - a.score);
    countries = meta.countries;
    technologies = meta.technologies;
    // console.log("results", dataObject);
  }

  return (
    <Box paddingY={4}>
      <div
        style={{
          display: "flex",
          width: "50%",
          margin: "50px auto",
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <table style={{ margin: "auto", marginTop: "0px" }}>
          <caption style={{ minWidth: 450, marginBottom: "40px", fontSize: "24px" }}>Countries by score</caption>
          <tbody>
            <tr>
              <th style={{ textAlign: "left" }}>Country</th>
              <th style={{ textAlign: "left" }}>Score &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </th>
              <th style={{ textAlign: "left" }}>Networks &nbsp; &nbsp; </th>
              <th style={{ textAlign: "left" }}>Operators</th>
            </tr>
            {countriesWithScore.map((item: { country_id: number; score: number; network_count: number; operator_count: number }) => (
              <tr key={item.country_id}>
                <td>{countries[item.country_id]}</td>
                <td>{Math.floor(item.score * 10) / 10}</td>
                <td>{item.network_count}</td>
                <td>{item.operator_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/*         <table style={{ margin: "auto", marginTop: "0px" }}>
          <caption style={{ marginBottom: "40px", fontSize: "24px" }}>Technologies</caption>
          <tbody>
            {Object.entries(technologies).map(item => (
              <tr key={item[0]}>
                <td>{item[0]}</td>
                <td>{item[1]}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
        {/*         <table style={{ margin: "auto", marginTop: "0px" }}>
          <caption style={{ marginBottom: "40px", fontSize: "24px" }}>Countries</caption>
          <tbody>
          {Object.entries(countries).map(item => (
            <tr>
              <td>
                {item[0]} {item[1]}
              </td>
            </tr>
          ))}
          </tbody>
        </table> */}
      </div>
    </Box>
  );
};

export default Table;
