import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../constants";
import { useGetVerticalsAsTreedataQuery, useGetVerticalsQuery } from "../../store/apis/search";
import { VerticalTreeType } from "../../types";
import { PrivateNetworksValuesType } from "../../utils";
import TreeChart from "../TreeChart";

interface NetworkTreeChartByVerticalProps {
  filters: PrivateNetworksValuesType;
  loading?: boolean;
}

interface TreeChartType {
  x: string;
  y: number;
}

const NetworkTreeChartByVertical: React.FC<NetworkTreeChartByVerticalProps> = ({ filters, loading }) => {
  const [canvas, setCanvas] = useState<any>(null);
  const [mappedData, setmappedData] = useState<TreeChartType[] | null>(null);
  const [singleVerticalName, setsingleVerticalName] = useState<string | null | undefined>(null);
  const { data } = useGetVerticalsAsTreedataQuery({ values: filters });
  const { data: verticalsOptions = [], isLoading: isVerticalsLoading } = useGetVerticalsQuery();

  useEffect(() => {
    // console.log("data", data, filters)
    if (data) {
      const newData = data.map((item: VerticalTreeType) => ({
        x: item.title,
        y: item.count,
      }));
      setmappedData(newData);
      let singleVertical: any = verticalsOptions.find(item => item.id === filters.verticals[0]);
      if (!singleVertical) {
        //if not found in verticals look in subverticals
        singleVertical = verticalsOptions
          .map(vertical => vertical.subvertical)
          .flat()
          .find(subvertical => subvertical.id === filters.verticals[0]);
      }
      setsingleVerticalName(singleVertical?.title);
    }
  }, [data]);

  return (
    <Box paddingY={4}>
      {mappedData?.length === 0 && (
        <>
          <TreeChart loading={loading} data={[{ data: [{ x: singleVerticalName ?? "o", y: 245 }] }]} colors={colors} setRef={setCanvas} />
        </>
      )}
      {mappedData ? (
        <>
          {mappedData?.length !== 0 && (
            <TreeChart
              key={`aaa${mappedData.join("-")}`}
              loading={loading}
              data={[{ data: mappedData }]}
              colors={colors}
              setRef={setCanvas}
            />
          )}
        </>
      ) : (
        <>
          <div style={{ minHeight: "560px" }}>Loading...</div>
        </>
      )}
    </Box>
  );
};

export default NetworkTreeChartByVertical;
