import { SatVendor, Sunset, UpgradeTech } from "../types/entities";

export const spreadTitles = (myArray: any[]) => {
  let result = "";
  myArray.forEach((item, index) => {
    if (index === 0) {
      result += item.title;
    } else {
      result += ", " + item.title;
    }
  });
  return result;
};

export const conditionalMonth = (month: string | undefined) => {
  if (month) {
    return month + "/";
  } else {
    return "";
  }
};

export const conditionalUpgradeTech = (upgradeTech: UpgradeTech[] | undefined) => {
  if (upgradeTech && upgradeTech?.length > 0) {
    let returnedString = " => ";
    upgradeTech.forEach((item, index) => {
      if (index === 0) {
        returnedString += item.title;
      } else {
        returnedString += ", " + item.title;
      }
    });
    return returnedString;
  } else {
    return "";
  }
};

export const formatSwitchOffs = (columnId: number, switchoffArray: [Sunset]) => {
  const tech2g = switchoffArray.find(item => item.technology === "2G");
  const tech3g = switchoffArray.find(item => item.technology === "3G");

  if (switchoffArray?.length > 0) {
    if (columnId === 1 && tech2g) {
      const allRecords = [];
      allRecords.push(`${conditionalMonth(tech2g.sunset_month)}${tech2g.sunset_year}${conditionalUpgradeTech(tech2g.upgrade_technology)}`);
      return allRecords.join("<br />");
    }
    if (columnId === 2 && tech2g) {
      return tech2g.status;
    }
    if (columnId === 3 && tech3g) {
      const allRecords = [];
      allRecords.push(`${conditionalMonth(tech3g.sunset_month)}${tech3g.sunset_year}${conditionalUpgradeTech(tech3g.upgrade_technology)}`);
      return allRecords.join("<br />");
    }
    if (columnId === 4 && tech3g) {
      return tech3g.status;
    }
  }
  return "";
};
export const formatSatVendors = (satVendorArray: [SatVendor]) => {
  if (satVendorArray?.length > 0) {
    const allRecords = [];
    for (let i = 0; i < satVendorArray?.length; i++) {
      allRecords.push(
        `${satVendorArray[i].vendor.title} ${satVendorArray[i].status} ${conditionalMonth(satVendorArray[i].start_month)}${
          satVendorArray[i].start_year
        }`
        // ${conditionalUpgradeTech(satVendorArray[i].upgrade_technology)}
      );
    }
    //return allRecords.join(", ");
    return allRecords.join("<br />");
  } else {
    return "";
  }
};
