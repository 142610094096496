import React from "react";
import { Typography } from "@mui/material";

import { useGetSettingsQuery } from "../../store/apis/search";

export interface LastUpdatedProps {
  sectionName?: string;
}

const LastUpdated: React.FC<LastUpdatedProps> = ({ sectionName }) => {
  const { data = "" } = useGetSettingsQuery(sectionName);

  return data ? <Typography>Database last updated: {data}</Typography> : <></>;
};

export default LastUpdated;
