import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiResponseType, SaveType } from "../../types";
import { baseUrl, prepareHeaders } from "../../constants/api";

export const saveApi = createApi({
  reducerPath: "saveApi",
  tagTypes: ["Search"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getSearches: builder.query<SaveType[], { token?: string; sectionId?: number }>({
      async queryFn({ sectionId }, _queryApi, _extraOptions, baseQuery) {
        const response = (await baseQuery(`saved-searches?${sectionId ? `&section_id=${sectionId}` : ""}`)) as {
          data: ApiResponseType;
        };

        if (response.data) {
          if (response.data.status === "error") {
            return {
              error: {
                status: 0,
                data: response.data.message || "No token.",
              },
            };
          }

          return {
            data: response.data.results as SaveType[],
          };
        }

        return {
          error: {
            status: 500,
            data: "Server error.",
          },
        };
      },
      providesTags: ["Search"],
    }),
    deleteSearch: builder.mutation<ApiResponseType, { id: number; token?: string }>({
      query: ({ id }) => ({
        url: `saved-searches/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Search"],
    }),
    saveSearch: builder.mutation<ApiResponseType, { token?: string; sectionId: number; data: any }>({
      query: ({ sectionId, data }) => ({
        url: `saved-searches`,
        method: "POST",
        body: {
          section_id: sectionId,
          ...data,
        },
      }),
      invalidatesTags: ["Search"],
    }),
    updateSearchTitle: builder.mutation<ApiResponseType, { token?: string; id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `saved-searches/${id}`,
        method: "PUT",
        body: {
          title: data.title,
        },
      }),
      invalidatesTags: ["Search"],
    }),
  }),
});

export const { useGetSearchesQuery, useSaveSearchMutation, useDeleteSearchMutation, useUpdateSearchTitleMutation } = saveApi;

export default saveApi;
