import React from "react";
import { Box, LinearProgress, AppBar, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useAppBarStyles = makeStyles<Theme, { top?: number }>(theme => ({
  root: {
    backgroundColor: "transparent",
  },
  positionSticky: {
    [theme.breakpoints.up("lg")]: {
      top: ({ top }) => top || 0,
    },
  },
}));

export interface AppLoaderProps {
  loading?: boolean;
  top?: number;
}

const AppLoader: React.FC<AppLoaderProps> = ({ loading, top }) => {
  const appBarStyles = useAppBarStyles({ top });

  return (
    <AppBar position="sticky" component="div" classes={appBarStyles}>
      {loading ? (
        <Box width="100%" position="relative" height={4}>
          <LinearProgress />
        </Box>
      ) : (
        <Box width="100%" position="relative" style={{ background: "#fff" }} height={4}></Box>
      )}
    </AppBar>
  );
};

export default AppLoader;
