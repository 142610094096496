import React, { useEffect, useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio, Typography, Grid, Button } from "@mui/material";

import PieChart from "../PieChart";
import { colors } from "../../constants";
import { exportImage } from "../../utils";
import { Image } from "@mui/icons-material";

interface DevicesPieChartProps {
  data: any;
  type: string;
  setType: (numeric: string) => void;
  loading?: boolean;
}

const DevicesPieChart: React.FC<DevicesPieChartProps> = ({ data = [], type, setType, loading }) => {
  const [numeric, setNumeric] = useState("total");
  const [canvas, setCanvas] = useState<any>(null);
  const [show, setShow] = useState("top_5");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data.length !== total) {
      setTotal(data.length);
    }
  }, [data, total]);

  const mappedData1 = data.map((item: any) => ({
    label: item.vendor_title || item.form_factor_title || "",
    value: numeric === "total" ? item.numeric : item.percentage,
  }));
  const mappedData = mappedData1.reduce((accumulator: any[], b: any) => {
    const fraction = show === "top_5" ? 0.05 : show === "top_10" ? 0.1 : show === "top_50" ? 0.5 : 1;
    if (accumulator.length < Math.ceil(mappedData1.length * fraction)) {
      return [...accumulator, b];
    } else {
      const values: number[] = accumulator.map(item => item.value);
      const smallest = Math.min(...values);

      let found = false;

      return accumulator.map(item => {
        if (!found && item.value === smallest && b.value > item.value) {
          found = true;

          return b;
        }

        return item;
      });
    }
  }, []);

  return (
    <>
      <Box marginY={16}>
        <Box marginY={16}>
          <RadioGroup
            name="type"
            value={type}
            row
            onChange={event => {
              setType(event.target.value);
            }}
          >
            <FormControlLabel value="chart_vendor" control={<Radio color="primary" />} label="Show vendors by device count" />
            <FormControlLabel value="chart_form_factor" control={<Radio color="primary" />} label="Chart by form factor" />
          </RadioGroup>
        </Box>
        <Box marginY={16}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">{type === "chart_vendor" ? "Vendors" : "Form Factors"}</Typography>
            </Grid>
            <Grid item>
              <Button
                startIcon={<Image />}
                variant="outlined"
                onClick={() => {
                  if (canvas) {
                    exportImage(canvas.canvas, "devices-pie-chart");
                  }
                }}
              >
                Download PNG
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box marginY={16}>
          <RadioGroup
            name="numeric"
            value={numeric}
            row
            onChange={(_, value) => {
              setNumeric(value);
            }}
          >
            <FormControlLabel disabled={loading} value="total" control={<Radio color="primary" />} label="Show as total" />
            <FormControlLabel disabled={loading} value="percent" control={<Radio color="primary" />} label="Show as percent" />
          </RadioGroup>
          <RadioGroup
            name="show"
            value={show}
            row
            onChange={(_, value) => {
              setShow(value);
            }}
          >
            <FormControlLabel disabled={loading} value="all" control={<Radio color="primary" />} label="Show all" />
            <FormControlLabel
              disabled={loading}
              value="top_50"
              control={<Radio color="primary" />}
              label="Top 50% vendors by device count"
            />
            <FormControlLabel
              disabled={loading}
              value="top_10"
              control={<Radio color="primary" />}
              label="Top 10% vendors by device count"
            />
            <FormControlLabel disabled={loading} value="top_5" control={<Radio color="primary" />} label="Top 5% vendors by device count" />
          </RadioGroup>
        </Box>
        {mappedData.length > 0 && (
          <PieChart
            loading={loading}
            setRef={setCanvas}
            title="Device growth over time"
            data={mappedData}
            valueSuffix={numeric === "percent" ? "%" : ""}
            colors={[...colors].slice(0, mappedData.length - 1)}
          />
        )}
      </Box>
    </>
  );
};

export default DevicesPieChart;
