import { Close } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { navigation } from "../../constants";
import allRoutes, { RouteProps } from "../../constants/routes";
import { signOut } from "../../store/slices/auth";
import { selectUiState, setMobileNavOpen } from "../../store/slices/ui";
import { variants } from "../../theme/variants";
import HeaderLogo from "../HeaderLogo";
import colors from "../../theme/colors";

const MobileNav: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();

  const useDrawStyles = makeStyles(() => ({
    root: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    paperAnchorDockedLeft: {
      borderWidth: 0,
    },
    paper: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 340,
      },
      backgroundColor: theme.palette.grey[500],
    },
  }));
  const drawStyles = useDrawStyles();
  const { mobileNavOpen } = useSelector(selectUiState);

  const path = `/${pathname.split("/")[1]}`;
  const menu: RouteProps[] = Object.values(allRoutes).map((route: RouteProps) => route);

  return (
    <Drawer open={mobileNavOpen} classes={drawStyles}>
      <ThemeProvider theme={variants.DARK}>
        <ClickAwayListener
          onClickAway={() => {
            dispatch(setMobileNavOpen(false));
          }}
        >
          <Box
            bgcolor="grey.475"
            color="rgba(255, 255, 255, 0.95)"
            width="100%"
            height="100%"
            overflow="auto"
            sx={{ background: "rgb(29, 33, 40)" }}
          >
            <Box bgcolor="grey.500" style={{ background: "rgb(17, 21, 28)" }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <HeaderLogo />
                </Grid>
                <Grid item>
                  <Box paddingX={2}>
                    <IconButton
                      onClick={() => {
                        dispatch(setMobileNavOpen(false));
                      }}
                      size="large"
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box bgcolor="grey.400" paddingY={2} style={{ background: "rgb(65, 68, 73)" }}>
              <List>
                {menu.map((route, index) => (
                  <ListItem
                    key={index}
                    href={route.path}
                    component={Link}
                    onClick={() => {
                      dispatch(setMobileNavOpen(false));
                    }}
                    /* selected={route.path === path} */
                  >
                    <ListItemText
                      primary={route.label}
                      primaryTypographyProps={{
                        color: "textPrimary",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box bgcolor="grey.475" paddingY={2}>
              <List>
                {navigation.map((item, index) => (
                  <ListItem
                    key={index}
                    href={item.url}
                    component={Link}
                    target="_blank"
                    onClick={() => {
                      dispatch(setMobileNavOpen(false));
                    }}
                  >
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        color: "textSecondary",
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem
                  key={navigation.length}
                  /* button */
                  color="textSecondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(signOut());
                  }}
                >
                  <ListItemText
                    data-testid="sign-out"
                    primary="Sign out"
                    primaryTypographyProps={{
                      component: "span",
                      color: "textSecondary",
                    }}
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </ClickAwayListener>
      </ThemeProvider>
    </Drawer>
  );
};

export default MobileNav;
