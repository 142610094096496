import React from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import allRoutes from "../constants/routes";
import { useGetOperatorQuery } from "../store/apis/search";
import ContentWrapper from "../components/ContentWrapper";
import FilterNav from "../components/FilterNav";
import DetailTable, { DetailTableRowType } from "../components/DetailTable";
import { monthNames } from "../constants";
import AppLoader from "../components/AppLoader";
import PageHeading from "../components/PageHeading";

const OperatorsDetail: React.FC = () => {
  const { id } = useParams();
  const { data, isFetching, isLoading } = useGetOperatorQuery(id || "");

  const techData: DetailTableRowType = {
    headings: [],
    rows: [],
  };
  const bandData: DetailTableRowType = {
    headings: [],
    rows: [],
  };
  const regionData: DetailTableRowType = {
    headings: [],
    rows: [],
  };
  const speedData: DetailTableRowType = {
    headings: [],
    rows: [],
  };
  const featuresData: DetailTableRowType = {
    headings: [],
    rows: [],
  };

  if (!id) {
    return <Navigate to={`${allRoutes.operators.path}/search`} />;
  }

  if (data) {
    if (data?.networks?.length) {
      techData.headings.push("Network", "Status", "Date");

      data.networks.forEach((value: any) => {
        techData.rows.push([
          value.technology.map((tech: any) => tech.title).join(", "),
          value.status_title,
          value.start_year !== 0 ? `${value.start_month ? `${monthNames[parseInt(value.start_month) - 1]} ` : ""}${value.start_year}` : "--",
        ]);
      });
    }

    if (data?.frequency_bands?.length) {
      bandData.headings.push("Band", "Status", "Date");

      data.frequency_bands.forEach((value: any) => {
        bandData.rows.push([
          `${value.title} (${value.range}) for ${value.technology_title}`,
          value.status_title,
          value.year !== 0 ? `${value.month ? `${monthNames[parseInt(value.month) - 1]} ` : ""}${value.year}` : "--",
        ]);
      });
    }

    const row: string[] = [];

    if (data.country) {
      regionData.headings.push("Country");
      row.push(data.country);
    }

    if (data?.regions?.length) {
      regionData.headings.push("Region");
      row.push(data.regions.map((region: any) => region.title).join(", "));
    }

    // Display the public status of the operator.
    if (data.public_status_id) {
      regionData.headings.push("Public Status");
      row.push(data.public_status_id === 1 ? "Public" : "Private");
    }

    regionData.rows.push(row);

    if (data?.speeds?.length) {
      const row2: string[] = [];

      data.speeds.forEach((value: any) => {
        speedData.headings.push(value.speed_type_title);
        row2.push(value.title);
      });

      speedData.rows.push(row2);
    }

    if (data?.features?.length) {
      featuresData.headings.push("Feature");

      data.features.forEach((value: any) => {
        featuresData.rows.push([value.title]);
      });
    }
  }

  return (
    <>
      {data && <Helmet title={`${data.title} - GSA GAMBoD`} />}
      <FilterNav />
      <PageHeading title={data?.title || "\u00A0"} icon={allRoutes.operators.icon} color={allRoutes.operators.color} section="operators">
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.operators.path}>Operators</Link>
          <Typography>{data?.title || "\u00A0"}</Typography>
        </Breadcrumbs>
      </PageHeading>
      <AppLoader loading={isLoading || isFetching} />
      <ContentWrapper body inset>
        {regionData.rows.length > 0 && <DetailTable data={regionData} />}
        {techData.rows.length > 0 && <DetailTable title="Networks" data={techData} />}
        {bandData.rows.length > 0 && <DetailTable title="Bands" data={bandData} />}
        {featuresData.rows.length > 0 && <DetailTable title="Features" data={featuresData} />}
        {speedData.rows.length > 0 && <DetailTable title="Speeds" data={speedData} />}
      </ContentWrapper>
    </>
  );
};

export default OperatorsDetail;
