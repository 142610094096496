import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
//import type {} from "redux-thunk/extend-redux"; // https://github.com/reduxjs/redux-thunk/issues/333

import { AllocationsCountryType } from "../../types";
import { nextCountry, selectAllocations, selectAllocationsIds } from "../../store/slices/allocations";
import AllocationLoader from "./AllocationLoader";
import AllocationsListItem from "./AllocationsListItem";

interface AllocationsListProps {
  data?: AllocationsCountryType[];
  values: any;
  showCurrent?: boolean;
  showFuture?: boolean;
  min: number;
  max: number;
}

const AllocationsList: React.FC<AllocationsListProps> = ({ data = [], values, showCurrent = false, showFuture = true, min, max }) => {
  const dispatch = useDispatch<any>();
  const countries = useSelector(selectAllocations);
  const ids = useSelector(selectAllocationsIds);

  const setInView = useCallback(() => {
    const country = data.find(item => !ids.includes(item.country_id));

    if (country) {
      dispatch(nextCountry({ countryId: country.country_id, values }));
    }
  }, [dispatch, data, ids, values]);

  return (
    <Box width="100%">
      {data
        .filter(item => ids.includes(item.country_id))
        .map((item, index) => {
          const country = countries.find(country => country.id === item.country_id);

          return (
            <AllocationsListItem
              key={index}
              index={index}
              allocations={country?.allocations || []}
              showFuture={showFuture}
              showCurrent={showCurrent}
              data={item}
              min={min}
              max={max}
            />
          );
        })}
      {data.some(item => !ids.includes(item.country_id)) && <AllocationLoader setInView={setInView} />}
    </Box>
  );
};

export default AllocationsList;
