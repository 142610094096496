const overrides = {
  MuiCssBaseline: {
    "@global": {
      html: {
        WebkitFontSmoothing: "auto",
        height: "100%",
      },
      body: {
        height: "100%",
      },
      "#root": {
        height: "100%",
      },
    },
  },
  MuiTabPanel: {
    root: {
      //padding: 0,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "0.85rem",
    },
  },
};

export default overrides;
