import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Divider, Grid, Link } from "@mui/material";
import { Place, Public } from "@mui/icons-material";
import { isEqual } from "lodash";

import { mapValues, mapValuesToSaveData } from "../../utils";
import { maxMin } from "../../constants";
import allRoutes from "../../constants/routes";
import {
  initialValues,
  selectAllocationsSlice,
  setSavedValues,
  setValues as setAllocationsValues,
  setSubRegions,
  setAllSubRegions,
} from "../../store/slices/allocations";
import { closeAllocationsFilters, selectUiState, setAllocationsFilters, setFiltersOpen } from "../../store/slices/ui";
import { useGetRegionsQuery, useGetChildRegionsQuery, useGetCountriesQuery, useGetItuRegionsQuery } from "../../store/apis/search";
import { useSaveSearchMutation } from "../../store/apis/save";
import { selectSaveToken } from "../../store/slices/auth";
import FilterList from "../FilterList";
import SearchFilter from "../SearchFilter";
import SpeedRangeFilter from "../SpeedRangeFilter";
import ExternalLinks from "../ExternalLinks";

const AllocationsForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { values: valuesCurrent, lastSaved, filteredSubRegions } = useSelector(selectAllocationsSlice);
  const { allocations: filters } = useSelector(selectUiState);
  const token = useSelector(selectSaveToken);
  const [activeCurrent, setActive] = useState(valuesCurrent.frequencyBand);
  const [
    [band1Min, band1Max],
    [band2Min, band2Max],
    [band3Min, band3Max],
    //[band4Min, band4Max],
  ] = maxMin;

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(setFiltersOpen(false));
      dispatch(setAllocationsValues(values));

      const { path } = allRoutes.spectrum_assignment;

      if (pathname === path) {
        navigate(`${path}/search`);
      }
    },
  });

  const { data: ituRegionsOptions = [], isLoading: isItuRegionsLoading } = useGetItuRegionsQuery();
  const { data: regionsOptions = [], isFetching: regionsIsLoading } = useGetRegionsQuery();

  const { data: childRegionOptions = [], isLoading: isChildRegionsLoading } = useGetChildRegionsQuery();

  const { data: countriesOptions = [], isFetching: countriesIsLoading } = useGetCountriesQuery({
    ituRegions: values.ituRegions,
    regions: values.regions,
  });
  const [saveSearch] = useSaveSearchMutation();

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  useEffect(() => {
    const countriesFiltered = values.countries.filter(option => mapValues(countriesOptions).includes(option));

    if (!isEqual(countriesFiltered, values.countries)) {
      setFieldValue("countries", countriesFiltered);
    }
  }, [countriesOptions, values.countries, setFieldValue]);

  useEffect(() => {
    if (!isChildRegionsLoading) {
      // console.log("set all subRegions");
      dispatch(setAllSubRegions(childRegionOptions));
      dispatch(setSubRegions([]));
    }
  }, [childRegionOptions, dispatch, isChildRegionsLoading]);

  const setFilterOpen = useCallback(
    (key: string, open: boolean) => {
      dispatch(setAllocationsFilters({ ...filters, [key]: open }));
    },
    [dispatch, filters]
  );

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 6,
      data: mapValuesToSaveData(6, valuesCurrent),
    });
  };

  return (
    <>
      <FilterList
        disabled={regionsIsLoading || countriesIsLoading}
        canSearch={!isEqual(values, valuesCurrent)}
        canSave={!isEqual(valuesCurrent, lastSaved)}
        onSubmit={handleSubmit}
        onReset={handleReset}
        onSave={handleSave}
      >
        <Box paddingX={4} paddingY={2} textAlign="right" bgcolor="grey.450">
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(closeAllocationsFilters());
            }}
          >
            Close all
          </Button>
        </Box>
        <Divider />
        <SearchFilter
          title="ITU Regions"
          name="itu-regions-filter"
          tooltip="Filter the search by ITU Region"
          value={ituRegionsOptions.filter(option => values.ituRegions.includes(option.id))}
          icon={<Public fontSize="small" />}
          options={ituRegionsOptions}
          loading={isItuRegionsLoading}
          disabled={!ituRegionsOptions.length}
          open={filters.ituRegions}
          onChange={value => {
            setFieldValue("ituRegions", mapValues(value));
            setFieldValue("regions", []);

            if (!value.length) {
              setFieldValue("countries", []);
            }
          }}
          setOpen={value => setFilterOpen("ituRegions", value)}
        />
        <SearchFilter
          title="Main Regions"
          name="regions-filter"
          tooltip="Regions are based on ITU definitions."
          value={regionsOptions.filter(option => values.regions.includes(option.id))}
          icon={<Public fontSize="small" />}
          options={regionsOptions}
          loading={regionsIsLoading}
          disabled={!regionsOptions.length}
          open={filters.regions}
          onChange={value => {
            setFieldValue("ituRegions", []);
            setFieldValue("regions", mapValues(value));
            dispatch(setSubRegions(value.map(item => item.id)));

            if (!value.length) {
              setFieldValue("countries", []);
            }
          }}
          setOpen={value => setFilterOpen("regions", value)}
        />
        <SearchFilter
          title="Sub Regions"
          name="regions-filter"
          tooltip="Filter the search by Region"
          value={filteredSubRegions.filter(option => values.regions.includes(option.id))}
          icon={<Public fontSize="small" />}
          options={filteredSubRegions}
          loading={isChildRegionsLoading}
          disabled={!filteredSubRegions.length}
          open={filters.childRegions}
          onChange={value => {
            setFieldValue("ituRegions", []);
            setFieldValue("childRegions", mapValues(value));
            setFieldValue("regions", mapValues(value));

            if (!value.length) {
              setFieldValue("countries", []);
            }
          }}
          setOpen={value => setFilterOpen("childRegions", value)}
        />
        <SearchFilter
          title="Country/Territories"
          name="countries-filter"
          tooltip="Countries are lined with ITU regions."
          value={countriesOptions.filter(option => values.countries.includes(option.id))}
          icon={<Place fontSize="small" />}
          options={countriesOptions}
          loading={countriesIsLoading}
          disabled={!countriesOptions.length}
          open={filters.countries}
          onChange={value => setFieldValue("countries", mapValues(value))}
          setOpen={value => setFilterOpen("countries", value)}
        />
        <SpeedRangeFilter
          title="Low Range"
          secondary="400 MHz to 1.6 GHz"
          tooltip="3GPP Low Band spectrum"
          name="low-range"
          min={band1Min}
          max={band1Max}
          value={values.frequencies[0]}
          active={activeCurrent === 0}
          current={valuesCurrent.frequencyBand === 0}
          marks={[1000]}
          open={filters.frequencyBand1}
          onChange={value => {
            setFieldValue("frequencyBand", 0);
            setFieldValue("frequencies", [value, values.frequencies[1], values.frequencies[2], values.frequencies[3]]);
          }}
          setOpen={value => {
            dispatch(
              setAllocationsFilters({
                ...filters,
                frequencyBand1: value,
                frequencyBand2: false,
                frequencyBand3: false,
                frequencyBand4: false,
              })
            );
            setActive(0);
            setFieldValue("frequencyBand", 0);
          }}
          onFocus={() => setActive(0)}
        />
        <SpeedRangeFilter
          title="Mid Range"
          secondary="1.6 GHz to 7.12 GHz"
          tooltip="3GPP Mid Band spectrum"
          name="mid-range"
          min={band2Min}
          max={band2Max}
          value={values.frequencies[1]}
          active={activeCurrent === 1}
          current={valuesCurrent.frequencyBand === 1}
          marks={[5000]}
          open={filters.frequencyBand2}
          onChange={value => {
            setFieldValue("frequencyBand", 1);
            setFieldValue("frequencies", [values.frequencies[0], value, values.frequencies[2], values.frequencies[3]]);
          }}
          setOpen={value => {
            dispatch(
              setAllocationsFilters({
                ...filters,
                frequencyBand1: false,
                frequencyBand2: value,
                frequencyBand3: false,
                frequencyBand4: false,
              })
            );
            setActive(1);
            setFieldValue("frequencyBand", 1);
          }}
          onFocus={() => setActive(1)}
        />
        <SpeedRangeFilter
          title="High Range"
          secondary="24.25 GHz to 52.6 GHz"
          tooltip="3GPP High Band spectrum"
          name="high-range"
          min={band3Min}
          max={band3Max}
          value={values.frequencies[2]}
          active={activeCurrent === 2}
          current={valuesCurrent.frequencyBand === 2}
          marks={[40000]}
          open={filters.frequencyBand3}
          onChange={value => {
            setFieldValue("frequencyBand", 2);
            setFieldValue("frequencies", [values.frequencies[0], values.frequencies[1], value, values.frequencies[3]]);
          }}
          setOpen={value => {
            dispatch(
              setAllocationsFilters({
                ...filters,
                frequencyBand1: false,
                frequencyBand2: false,
                frequencyBand3: value,
                frequencyBand4: false,
              })
            );
            setActive(2);
            setFieldValue("frequencyBand", 2);
          }}
          onFocus={() => setActive(2)}
        />
        <Box paddingX={8} paddingY={1}>
          <ExternalLinks />
        </Box>
      </FilterList>
    </>
  );
};

export default AllocationsForm;
