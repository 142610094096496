import React from "react";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ParentType } from "../../types";
import FilterListItem from "../FilterListItem";
import RadioFilterItem from "./RadioFilterItem";

const useListStyles = makeStyles(theme => ({
  root: {
    //border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    overflow: "hidden",
    padding: 0,
  },
}));

export interface RadioFilterProps {
  title?: string;
  name?: string;
  tooltip?: string;
  options?: ParentType[];
  value?: ParentType;
  icon?: any;
  loading?: boolean;
  disabled?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onChange: (value: ParentType | null) => void;
  selected?: number;
}

const RadioFilter: React.FC<RadioFilterProps> = ({
  title = "Checkbox Filter",
  name = "checkbox-filter",
  tooltip,
  options = [],
  value,
  icon,
  loading,
  disabled,
  open,
  setOpen,
  onChange,
  selected = 0,
}) => {
  const listStyles = useListStyles();

  return (
    <FilterListItem title={title} tooltip={tooltip} selected={selected} icon={icon} open={open} setOpen={setOpen}>
      <List id={name} dense classes={listStyles}>
        {loading ? (
          <ListItem dense>
            <ListItemText primary="Loading..." />
          </ListItem>
        ) : (
          options.map((option, index, array) => (
            <RadioFilterItem
              key={String(option.id)}
              option={option}
              value={value}
              disabled={disabled}
              onChange={onChange}
              last={index === array.length - 1}
            />
          ))
        )}
      </List>
      <Button
        id={`${name}-clear`}
        variant="outlined"
        size="small"
        onClick={() => {
          onChange(null);
        }}
      >
        Clear
      </Button>
    </FilterListItem>
  );
};

export default RadioFilter;
