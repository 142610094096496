import React, { useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio, Typography, Grid, Button } from "@mui/material";

import BarChart from "../BarChart";
import { exportImage } from "../../utils";
import { Image } from "@mui/icons-material";

interface DevicesBarChartProps {
  data: any;
  time: string;
  setTime: (value: string) => void;
}

const DevicesBarChart: React.FC<DevicesBarChartProps> = ({ data = [], time, setTime }) => {
  const [display, setDisplay] = useState("value");
  const [canvas, setCanvas] = useState<any>(null);
  const mappedData = data.map((item: any) => ({
    label: item.name,
    value: display === "value" ? item.total : item.percentage,
  }));

  return (
    <Box paddingY={8} width="100%">
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Device growth over time
          </Typography>
          <Box marginY={16}>
            <RadioGroup
              name="time"
              value={time}
              row
              onChange={(_, value) => {
                setTime(value);
              }}
            >
              <FormControlLabel value="months" control={<Radio color="primary" />} label="Show as months" />
              <FormControlLabel value="quarters" control={<Radio color="primary" />} label="Show as quarters" />
              <FormControlLabel value="years" control={<Radio color="primary" />} label="Show as years" />
            </RadioGroup>
            <RadioGroup
              name="display"
              value={display}
              row
              onChange={(_, value) => {
                setDisplay(value);
              }}
            >
              <FormControlLabel value="value" control={<Radio color="primary" />} label="Show as value" />
              <FormControlLabel value="percent" control={<Radio color="primary" />} label="Show as percent" />
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item>
          <Button
            startIcon={<Image />}
            variant="outlined"
            onClick={() => {
              if (canvas) {
                exportImage(canvas.canvas, "devices-bar-chart");
              }
            }}
          >
            Download PNG
          </Button>
        </Grid>
      </Grid>
      {mappedData.length > 0 && <BarChart data={mappedData} setRef={setCanvas} />}
    </Box>
  );
};

export default DevicesBarChart;
