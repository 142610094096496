import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Typography,
  FormControlLabel,
  Switch,
  AppBar,
  Hidden,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isEqual } from "lodash";

import { selectAllocationsSlice, setSavedValues } from "../store/slices/allocations";
import { useGetAllocationsQuery, useGetAllocationsV2Query } from "../store/apis/search";
import ContentWrapper from "../components/ContentWrapper";
import allRoutes from "../constants/routes";
import SaveSearch from "../components/SaveSearch";
import AllocationsList from "../components/AllocationsList";
import FilterNav from "../components/FilterNav";
import AllocationsGauge from "../components/AllocationsGauge";
import FilterListTooltip from "../components/FilterListTooltip";
import { useSaveSearchMutation } from "../store/apis/save";
import { selectSaveToken } from "../store/slices/auth";
import { mapValuesToSaveData } from "../utils";
import PageHeading from "../components/PageHeading";

const AllocationsSearch: React.FC = () => {
  const dispatch = useDispatch();
  const [showCurrent, setShowCurrent] = useState(true);
  const [showFuture, setShowFuture] = useState(false);
  const theme = useTheme();
  const useAppBarStyles = makeStyles(() => ({
    root: {
      backgroundColor: "transparent",
    },
    positionSticky: {
      overflow: "hidden",
      [theme.breakpoints.up("lg")]: {
        top: 52,
      },
    },
  }));
  const appBarStyles = useAppBarStyles();
  const { values, lastSaved } = useSelector(selectAllocationsSlice);
  const token = useSelector(selectSaveToken);
  const { data = { results: [], total: 0, lowest_band: 0, highest_band: 0 }, isLoading, isFetching } = useGetAllocationsV2Query(values);
  const { total = 0, results = [], lowest_band, highest_band } = data;
  const loading = !data || isLoading || isFetching;
  const [min, max] = values.frequencyBand === -1 ? [lowest_band, highest_band] : values.frequencies[values.frequencyBand];

  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 6, data: mapValuesToSaveData(6, values) });
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
    </Box>
  );

  const getValue = (value: number) => `${value >= 1000 ? Math.floor(value / 10) / 100 : value} ${value < 1000 ? "MHz" : "GHz"}`;

  const [allocationsTotal, setallocationsTotal] = useState("0");
  useEffect(() => {
    setallocationsTotal(sessionStorage.getItem("allocationsTotal") || "0");
  }, []);

  return (
    <>
      <Helmet title="Spectrum Assignment - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={allRoutes.spectrum_assignment.label}
        icon={allRoutes.spectrum_assignment.icon}
        color={allRoutes.spectrum_assignment.color}
        section="spectrum"
        inset
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.spectrum_assignment.path}>Spectrum Assignment</Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs zeroMinWidth>
            <Box paddingY={10}>
              <Grid container spacing={8} alignItems="flex-end" wrap="nowrap">
                <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">{loading ? "--" : `${total.toLocaleString()} Assignments`}</Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Frequency range
                  </Typography>
                  <Typography variant="h4">{`${getValue(min)} to ${getValue(max)}`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
            {renderButtons()}
          </Grid>
        </Grid>
        <Divider />
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          {renderButtons()}
          <Divider />
        </ContentWrapper>
      </Box>
      <AppBar position="sticky" component="div" classes={appBarStyles}>
      <ContentWrapper inset>
        <Box paddingY={2}>
          <Grid container spacing={4} alignItems="center">
            <Grid item zeroMinWidth>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item zeroMinWidth>
                  <FilterListTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                </Grid>
                <Grid item zeroMinWidth color="black">
                  <FormControlLabel
                    label="Show current assignments"
                    checked={showCurrent}
                    onChange={(_, checked) => {
                      setShowCurrent(checked);
                    }}
                    control={<Switch color="primary" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item zeroMinWidth>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item zeroMinWidth>
                  <FilterListTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                </Grid>
                <Grid item zeroMinWidth color="black">
                  <FormControlLabel
                    label="Show future spectrum plans"
                    checked={showFuture}
                    onChange={(_, checked) => {
                      setShowFuture(checked);
                    }}
                    control={<Switch color="primary" />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ContentWrapper>
        <AllocationsGauge min={min} max={max} />
        {loading ? (
          <Box width="100%" position="relative" height={4}>
            <LinearProgress />
          </Box>
        ) : (
          <Box width="100%" position="relative" style={{ background: "#fff" }} height={4}></Box>
        )}
      </AppBar>
      {!loading ? (
        <ContentWrapper inset body>
          <Box width="100%" paddingBottom={8}>
            <AllocationsList
              data={Object.values(results)}
              values={values}
              showFuture={showFuture}
              showCurrent={showCurrent}
              min={min}
              max={max}
            />
          </Box>
        </ContentWrapper>
      ) : (
        <ContentWrapper body>
          <Box marginY={16} sx={{ display: { xs: "none", md: "block" } }}>
            Fetching / filtering {allocationsTotal} records...
          </Box>
        </ContentWrapper>
      )}
    </>
  );
};

export default AllocationsSearch;
