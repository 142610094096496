import { AxiosResponse } from "axios";

import axios from "../utils/axios";
import { baseUrl } from "../constants/api";
import { SignInFromCookieType, SignInType } from "../types/auth";
import { prepareHeaders } from "../constants/api";

export interface ApiResponse {
  message: string;
  status: string;
  count?: number;
  total?: number;
  download?: string;
  results?: any;
}

declare global {
  interface Window {
    gtag: any;
  }
}

export const getRequest = async (endpoint: string) => {
  try {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      headers: prepareHeaders(new Headers()),
    });
    const operators = await response.json();
    return operators;
  } catch (error) {
    console.warn("There was an error whilst fetching data", error);
  }
};

export const ssoLoginRequest = (endpoint: string, body: SignInType) => {
  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios
      .post(endpoint, body)
      .then((response: AxiosResponse<any>) => {
        if (response?.statusText === "Success" && response.data && response.data.user) {
          const user = response.data.user;
          // Use gtag to set user details
          window.gtag("event", "log_in", {
            user_id: user.UserId, // Set user_id variable
            user_email: user.Email, // Set user_email variabe
            role: user.Roles.join(","), // Set role, assuming it's an array
          });
        }

        return resolve(response);
      })

      .catch(error => {
        reject(error);

        throw error;
      });
  });
};

export const ssoLoginFromCookieRequest = (endpoint: string, body: SignInFromCookieType) => {
  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios
      .post(endpoint, body)
      .then((response: AxiosResponse<any>) => {
        if (response?.statusText === "Success" && response.data && response.data.user) {
          const user = response.data.user;
          // Use gtag to set user details
          window.gtag("event", "log_in", {
            user_id: user.UserId, // Set user_id variable
            user_email: user.Email, // Set user_email variabe
            role: user.Roles.join(","), // Set role, assuming it's an array
          });
        }

        return resolve(response);
      })
      .catch(error => {
        reject(error);

        throw error;
      });
  });
};

export const postCookieRequest = (endpoint: string, body_data: object) => {
  return new Promise<ApiResponse>((resolve, reject) => {
    axios
      .post(`${baseUrl}${endpoint}`, {
        headers: prepareHeaders(new Headers()),
        data: body_data,
      })
      .then((response: AxiosResponse<ApiResponse>) => {
        const { status, data } = response;

        if (status === 200 && data) {
          if (data.status === "error") {
            reject(data.message);
          }

          resolve(data);
        }

        reject(response);
      })
      .catch(error => reject(error));
  });
};

export const logoutRequest = (endpoint: string) => {
  return new Promise<string>((resolve, reject) => {
    axios
      .get(`${baseUrl}${endpoint}`, {
        //@ts-ignore
        headers: prepareHeaders(new Headers()),
      })
      .then((response: AxiosResponse<string>) => {
        const { status, data } = response;

        if (status === 200 && data) {
          if (data === "error") {
            reject(data);
          }

          resolve(data);
        }

        reject(response);
      })
      .catch(error => reject(error));
  });
};
