import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Theme, ThemeProvider, Typography, useTheme } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { variants } from "../../theme/variants";

export interface DetailTableVerticalRowType {
  headings: string[];
  rows: string[][];
}

export interface DetailTableVerticalProps {
  title?: string;
  maxWidth?: number;
  data: DetailTableVerticalRowType;
}

const DetailTableVertical: React.FC<DetailTableVerticalProps> = ({ title, data, maxWidth = 800 }) => {
  const theme = useTheme();
  const useTableStyles = makeStyles(() => ({
    root: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderStyle: "solid",
      borderWidth: "0",
      minWidth: 680,
    },
  }));

  const useTableHeadStyles = makeStyles(() => ({
    root: {
      color: "#000 !important",
      width: "33.333%",
      borderWidth: "0 !important",
    },
  }));
  const useTableCellStyles = makeStyles(() => ({
    root: {
      borderWidth: "0 !important",
    },
  }));
  const tableStyles = useTableStyles();
  const headStyles = useTableHeadStyles();
  const cellStyles = useTableCellStyles();

  return (
    <Box marginY={16} maxWidth={maxWidth} className="detailTable">
      <TableContainer>
        {title && (
          <Box paddingBottom={4}>
            <Typography variant="h4">{title}</Typography>
          </Box>
        )}
        <Table classes={tableStyles} size="small">
          <TableBody>
            {data.headings.map((item, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 ? "#fff" : theme.palette.grey[50] }}>
                <TableCell key={0} classes={headStyles}>
                  <ThemeProvider theme={variants.DARK}>
                    <Typography>
                      <strong>{item}</strong>
                    </Typography>
                  </ThemeProvider>
                </TableCell>
                <TableCell key={1} classes={cellStyles}>
                  <Typography>{data.rows[0][index]}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailTableVertical;
