import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";

export interface ExportCsvProps {
  source?: string;
  loading?: boolean;
  onClick: () => void;
}

const ExportCsv: React.FC<ExportCsvProps> = ({ source, loading, onClick }) => {
  const useButtonStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));

  const buttonStyles = useButtonStyles();
  const [download, setDownload] = useState(source);

  useEffect(() => {
    if (source && source !== download) {
      const anchor = document.createElement("a");

      if (anchor) {
        anchor.href = source;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      setDownload(source);
    }
  }, [source, download]);

  const useTooltipStyles = makeStyles(() => ({
    popper: {
      zIndex: 2000,
    },
  }));
  const tooltipStyles = useTooltipStyles();

    return (
      <Button
        classes={buttonStyles}
        variant="outlined"
        onClick={onClick}
        startIcon={loading ? <CircularProgress size={20} thickness={6} /> : <GetApp />}
      >
        Export CSV
      </Button>
    );
};

export default ExportCsv;
