import React from "react";
import { TableRow as MuiTableRow, TableCell as MuiTableCell } from "@mui/material";
import { sanitize } from "../../utils/sanitize";

import makeStyles from "@mui/styles/makeStyles";

const useCellStyles = makeStyles(theme => ({
  root: {
    fontSize: "inherit",
    paddingLeft: 0,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    verticalAlign: "top",
    padding: "12px 0px 12px 16px !important", // pretty nasty, right?
  },
  sizeSmall: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },
}));

type TableCellType = string | JSX.Element;

export interface TableRowProps {
  row: TableCellType[];
}

const TableRow: React.FC<TableRowProps> = ({ row }) => {
  const cellStyles = useCellStyles();

  return (
    <MuiTableRow hover tabIndex={-1}>
      {row.map((cell, index) => {
        if (typeof cell === "string") {
          return <MuiTableCell key={index + 1} classes={cellStyles} dangerouslySetInnerHTML={{ __html: sanitize(cell) }}></MuiTableCell>;
        } else {
          return (
            <MuiTableCell key={index + 1} classes={cellStyles}>
              {cell}
            </MuiTableCell>
          );
        }
      })}
    </MuiTableRow>
  );
};

export default TableRow;
