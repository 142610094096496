import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InfoOutlined } from "@mui/icons-material";

export interface FilterListTooltipProps {
  title: string;
  ariaLabel?: string;
}

const FilterListTooltip: React.FC<FilterListTooltipProps> = ({ title, ariaLabel = "tooltip" }) => {
  const useIconStyles = makeStyles(() => ({
    root: {
      opacity: 0.75,
    },
  }));

  const useTooltipStyles = makeStyles(() => ({
    popper: {
      zIndex: 2000,
    },
  }));
  const iconStyles = useIconStyles();
  const tooltipStyles = useTooltipStyles();

  return (
    <Tooltip title={title} arrow placement="right" classes={tooltipStyles}>
      <IconButton aria-label={ariaLabel} edge="start" size="large">
        <InfoOutlined fontSize="small" classes={iconStyles} />
      </IconButton>
    </Tooltip>
  );
};

export default FilterListTooltip;
