import React from "react";
import { Box } from "@mui/material";

interface AuthProps {
  children?: React.ReactNode;
}

const Auth: React.FC<AuthProps> = ({ children }) => (
  <Box
    width="100%"
    style={{ minHeight: "100vh" }}
    maxWidth={400}
    margin="0 auto"
    justifyContent="center"
    alignItems="center"
    display="flex"
    minHeight="100%"
    flexDirection="column"
    paddingX={4}
  >
    {children}
  </Box>
);

export default Auth;
