import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import saveApi from "./apis/save";
import search from "./apis/search";

import auth from "./slices/auth";
import ui from "./slices/ui";
import theme from "./slices/theme";
import devices from "./slices/devices";
import chipsets from "./slices/chipsets";
import networks from "./slices/networks";
import privateNetworks from "./slices/privateNetworks";
import allocations from "./slices/allocations";
import operators from "./slices/operators";

const reducer = combineReducers({
  allocations,
  chipsets,
  devices,
  networks,
  privateNetworks,
  operators,
  theme,
  ui,
  auth,
  [search.reducerPath]: search.reducer,
  [saveApi.reducerPath]: saveApi.reducer,
});

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(search.middleware).concat(saveApi.middleware),
});

setupListeners(store.dispatch);

export type AppStateType = ReturnType<typeof reducer>;
export type AppDispatchType = typeof store.dispatch;

export default store;
