import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";

import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import allRoutes from "../constants/routes";

const MySearches: React.FC = () => (
  <>
    <PageHeading title="My Searches">
      <Breadcrumbs aria-label="Breadcrumb">
        <Link href={allRoutes.dashboard.path}>Dashboard</Link>
        <Typography>My Searches</Typography>
      </Breadcrumbs>
    </PageHeading>
    <SaveList />
  </>
);

export default MySearches;
