import React from "react";
import { Box, Grid, Typography, Divider, useMediaQuery, useTheme } from "@mui/material";

import ContentWrapper from "../ContentWrapper";
import AppAvatar from "../AppAvatar";
import LastUpdated from "../LastUpdated";
import theme from "../../store/slices/theme";

export interface PageHeadingProps {
  title: string;
  section?: string;
  color?: string;
  inset?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const PageHeading: React.FC<PageHeadingProps> = ({ title, icon, section, inset, color, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <ContentWrapper>
        <Box paddingY={4}>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "flex-end"}
            flexDirection={isMobile ? "column" : "row"}
            wrap="nowrap"
          >
            <Grid item xs zeroMinWidth>
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item zeroMinWidth>
                  <AppAvatar color={color}>{icon && icon}</AppAvatar>
                </Grid>
                <Grid item zeroMinWidth marginLeft={6} marginY={4}>
                  <Typography variant="h3">{title}</Typography>
                  {children}
                </Grid>
              </Grid>
            </Grid>
            {section && (
              <Grid item zeroMinWidth sx={{ display: { xs: "block", md: "block" } }}>
                <LastUpdated sectionName={section} />
              </Grid>
            )}
          </Grid>
        </Box>
        {inset && <Divider />}
      </ContentWrapper>
      {!inset && <Divider />}
    </>
  );
};

export default PageHeading;
