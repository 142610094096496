import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { lighten } from "@mui/material/styles";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useRef } from "react";
import { Pie } from "react-chartjs-2";

export interface PieChartItem {
  label: string;
  value: number;
}

export interface PieChartProps {
  title?: string;
  data: PieChartItem[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

const PieChart: React.FC<PieChartProps> = ({
  data = [],
  loading,
  cutoutPercentage = 0,
  rotation = 0,
  valueSuffix = "",
  colors,
  setRef,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const ref = useRef(null);

  useEffect(() => {
    if (setRef && ref?.current) {
      setRef(ref.current);
    }
  }, [setRef]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const chart = {
    labels: loading ? [] : data.map(data => `${data.label} (${data.value}${valueSuffix})`),
    datasets: [
      {
        data: data.map(data => data.value),
        backgroundColor: data.map((_, index) => colors[index % colors.length]),
        hoverBackgroundColor: data.map((_, index) => {
          let color = colors[index % colors.length];

          try {
            color = lighten(colors[index % colors.length], 0.25);
          } catch (error) {}

          return color;
        }),
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "center" as const,
        //display: false, //data.length < 80,
        position: isTablet ? ("bottom" as const) : ("right" as const),
        labels: { boxWidth: 6 },
        title: {
          display: false,
          text: "Legend Title",
        },
      },
    },

    title: {
      padding: {
        left: 50,
      },
    },
    layout: {
      padding: {
        left: 0,
      },
      borderWidth: 1,
      borderColor: "red",
      borderStyle: "solid",
    },
  };

  return (
    <Box maxWidth={1280} width="100%">
      <Box height={[720, 720, 640, 560]} marginY={8} width="100%">
        <Pie data={chart} options={options} ref={ref} />
      </Box>
    </Box>
  );
};

export default PieChart;
