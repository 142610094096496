import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStateType } from "../";
import { ChipsetsValuesType } from "../../utils";

export const initialValues: ChipsetsValuesType = {
  chipsetTypes: [],
  chipsetVendors: [],
  supports: [],
  ue: [],
  commercial: [0, 1, 2],
  technologies: [],
  startDate: null,
  endDate: null,
};

const chipsets = createSlice({
  name: "chipsets",
  initialState: {
    lastSaved: initialValues,
    values: initialValues,
    rowsPerPage: 20,
    page: 1,
    sortBy: "model",
    sortDesc: false,
  },
  reducers: {
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
      state.page = 1;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setValues: (state, { payload }: PayloadAction<ChipsetsValuesType>) => {
      state.values = payload;
      state.page = 1;
    },
    setSavedValues: (state, { payload }: PayloadAction<ChipsetsValuesType>) => {
      state.lastSaved = payload;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      if (state.sortBy.valueOf() !== payload) {
        state.sortBy = payload;
        state.sortDesc = false;
        state.page = 1;
      } else {
        state.sortDesc = !state.sortDesc.valueOf();
      }
    },
  },
});

export const { setRowsPerPage, setPage, setValues, setSortBy, setSavedValues } = chipsets.actions;

export const selectChipsetsSlice = (state: AppStateType) => state.chipsets;

export default chipsets.reducer;
