import { Helmet } from "react-helmet-async";
import { Box, Link, Typography } from "@mui/material";

import AuthLayout from "../../layouts/Auth";
import allRoutes from "../../constants/routes";

const Page404 = () => (
  <AuthLayout>
    <Box padding={[4, 4, 4, 8]}>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>
      <Box paddingTop={4}>
        <Box display="flex" justifyContent="center">
          <Link href={allRoutes.dashboard.path} /* variant="contained" color="secondary" */>Return to dashboard</Link>
        </Box>
      </Box>
    </Box>
  </AuthLayout>
);

export default Page404;
