import React from "react";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ParentType } from "../../types";
import FilterListItem from "../FilterListItem";
import CheckboxFilterItem from "./CheckboxFilterItem";

const useListStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    overflow: "hidden",
    padding: 0,
  },
}));

export interface CheckboxFilterProps {
  title?: string;
  name?: string;
  tooltip?: string;
  options?: ParentType[];
  value?: ParentType[];
  icon?: any;
  loading?: boolean;
  disabled?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onChange: (value: ParentType[]) => void;
  testId?: string;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  title = "Checkbox Filter",
  name = "checkbox-filter",
  tooltip,
  options = [],
  value = [],
  icon,
  loading,
  disabled,
  open,
  setOpen,
  onChange,
  testId,
}) => {
  const listStyles = useListStyles();

  return (
    <FilterListItem title={title} tooltip={tooltip} selected={value.length} icon={icon} open={open} setOpen={setOpen}>
      <List id={name} classes={listStyles} data-testid={testId}>
        {loading ? (
          <ListItem dense>
            <ListItemText primary="Loading..." />
          </ListItem>
        ) : (
          options
            .filter(option => [undefined, 0, null].includes(option.parent_id))
            .map((option, index, array) => (
              <CheckboxFilterItem
                key={String(option.id)}
                option={option}
                options={options.filter(item => item.parent_id === option.id)}
                value={value}
                disabled={disabled}
                onChange={onChange}
                last={index === array.length - 1}
              />
            ))
        )}
      </List>
      {options.length > 2 && (
        <Box marginTop={4}>
          <Button
            variant="outlined"
            size="small"
            disabled={disabled || loading || value.length === options.length}
            onClick={() => {
              onChange(options);
            }}
          >
            Select all
          </Button>
        </Box>
      )}
    </FilterListItem>
  );
};

export default CheckboxFilter;
