import React from "react";
import { Checkbox, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ParentType } from "../../types";

const useListItemStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(8),
  },
}));

export interface CheckboxFilterItemChildProps {
  option: ParentType;
  value?: ParentType[];
  disabled?: boolean;
  onChange: (checked: boolean, item: ParentType) => void;
}

const CheckboxFilterItemChild: React.FC<CheckboxFilterItemChildProps> = ({ option, value = [], disabled, onChange }) => {
  const listItemStyles = useListItemStyles();

  return (
    <ListItem
      key={`${option.id}`}
      dense
      button
      classes={listItemStyles}
      disabled={disabled}
      onClick={() => onChange(!value.includes(option), option)}
    >
      <ListItemIcon>
        <Checkbox
          name={`${option.id}`}
          color={disabled ? "default" : "primary"}
          checked={value.includes(option)}
          tabIndex={-1}
          onChange={(_, checked) => onChange(checked, option)}
        />
      </ListItemIcon>
      <ListItemText primary={option.title} />
    </ListItem>
  );
};

export default CheckboxFilterItemChild;
