import React from "react";
import { DateRangeOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Badge, Collapse, ListItem, ListItemText, ListItemIcon, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import FilterListTooltip from "../FilterListTooltip";

export interface FilterListItemProps {
  title: string;
  secondary?: string;
  tooltip?: string;
  selected: number | string;
  icon?: any;
  open?: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
  title,
  secondary,
  tooltip = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  selected = 0,
  icon = 0,
  open,
  children,
  setOpen,
}) => {
  const theme = useTheme();
  const useListItemStyles = makeStyles(() => ({
    root: {
      paddingTop: "4px !important",
      paddingBottom: "4px !important",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
    divider: {
      borderBottomColor: "rgba(255, 255, 255, 0.15)",
    },
  }));

  const useCollapseStyles = makeStyles(() => ({
    wrapperInner: {
      padding: theme.spacing(4),
    },
  }));

  const useBadgeStyles = makeStyles(() => ({
    root: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      marginRight: theme.spacing(7),
      //minWidth: "20px !important",
      minWidth: "70px !important",
      display: "flex",
      left: "-15px",
      position: "relative",
    },
    badge: {},
  }));

  const useIconStyles = makeStyles(() => ({
    root: {
      minWidth: 32,
      opacity: 0.75,
    },
  }));
  const listItemStyles = useListItemStyles();
  const badgeStyles = useBadgeStyles();
  const iconStyles = useIconStyles();
  const collapseStyles = useCollapseStyles();

  return (
    <>
      <ListItem divider classes={listItemStyles} onClick={() => setOpen(!open)} title={tooltip}>
        {tooltip && <FilterListTooltip title={tooltip} />}
        <ListItemIcon classes={iconStyles}>{icon || <DateRangeOutlined fontSize="small" />}</ListItemIcon>
        <ListItemText primary={title} secondary={secondary} />
        <Badge classes={badgeStyles} badgeContent={selected} color="primary" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse classes={collapseStyles} in={open}>
        {children}
      </Collapse>
    </>
  );
};

export default FilterListItem;
