import React from "react";
import { Divider, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ContentWrapper from "../ContentWrapper";

const useLeftStyles = makeStyles({
  root: {
    position: "absolute",
    bottom: 0,
    height: 8,
    borderLeft: "1px solid #999",
  },
});

const useGuageStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    right: 0,
    bottom: 8,
    whiteSpace: "nowrap",
    textAlign: "center",
    transform: "translateX(50%)",
    color: theme.palette.text.primary,
    fontSize: "0.75rem",
  },
}));

interface AllocationsGaugeProps {
  min: number;
  max: number;
}

const AllocationsGauge: React.FC<AllocationsGaugeProps> = ({ min, max }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const leftStyles = useLeftStyles();
  const gaugeStyles = useGuageStyles();
  const total = max - min;

  // Calculate appropriate step size.
  const stepSizes = [10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000];
  const stepSizeApprox = total / (isMobile ? 3 : 5);
  const stepSize = stepSizes.reduce(
    (closest, current) => (Math.abs(current - stepSizeApprox) < Math.abs(closest - stepSizeApprox) ? current : closest),
    25
  );

  const labels = [min];

  let ii = min;

  while (ii < max) {
    ii += stepSize;

    const value = Math.round(ii / stepSize) * stepSize;

    if (value < max - stepSize / 2) {
      labels.push(value);
    }
  }

  labels.push(max);

  return (
    <>
      <ContentWrapper bar inset>
        <Box paddingLeft={20}>
          <Box position="relative" height={48} borderBottom="2px solid #ccc">
            {labels.map((label, index) => (
              <Box key={index} className={leftStyles.root} style={{ left: `${((label - min) / total) * 100}%` }}>
                <Typography variant="body1" classes={gaugeStyles}>
                  {Math.floor(label / 10) / 100} GHz
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </ContentWrapper>
      <Divider style={{ backgroundColor: "#cccccc" }} />
    </>
  );
};

export default AllocationsGauge;
