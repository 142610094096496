import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";

import copy from "../../constants/copy";
import ContentWrapper from "../ContentWrapper";

const Footer: React.FC = () => (
  <>
    <Divider />
    <ContentWrapper body>
      <Box marginY={16} sx={{ display: { xs: "block", md: "none" } }}>
        <Box marginY={2}>
          <Typography gutterBottom>
            <Link href="https://gsacom.com/privacy-policy/" target="_blank">
              Privacy Policy
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link href="https://gsacom.com/cookie-policy/" target="_blank">
              Cookie Policy
            </Link>
          </Typography>
        </Box>
        <Box marginY={2}>
          <Typography>{copy.copyright}</Typography>
        </Box>
      </Box>
      <Box marginY={16} sx={{ display: { xs: "none", md: "block" } }}>
        <Typography>
          {copy.copyright}&nbsp;&nbsp;|&nbsp;&nbsp;
          <Link href="https://gsacom.com/privacy-policy/" target="_blank">
            Privacy Policy
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link href="https://gsacom.com/cookie-policy/" target="_blank">
            Cookie Policy
          </Link>
        </Typography>
      </Box>
    </ContentWrapper>
  </>
);

export default Footer;
