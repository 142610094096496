import React from "react";
import { Box, Typography } from "@mui/material";

const NoResults: React.FC = () => (
  <Box paddingY={16}>
    <Typography variant="h4" component="p" align="center">
      Search returned no results.
    </Typography>
  </Box>
);

export default NoResults;
