import React from "react";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface SelectProps {
  label?: string;
  name?: string;
  options: SelectOption[];
  value: string | number;
  minWidth?: number | string;
  disabled?: boolean;
  onChange: (value: string | number) => void;
}

const Select: React.FC<SelectProps> = ({ label, name = "select", options, value, minWidth, disabled, onChange }) => (
  <FormControl style={{ minWidth: minWidth || "auto" }}>
    {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
    <MuiSelect
      defaultValue=""
      id={`${name}-select`}
      labelId={`${name}-select-label`}
      //@ts-ignore
      value={value}
      displayEmpty={!!label}
      disabled={disabled}
      variant="standard"
      onChange={e => onChange(e.target.value as string)}
    >
      {options.map((option: SelectOption, index: number) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
);

export default Select;
