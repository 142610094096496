import { Box, Breadcrumbs, Divider, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import AppLoader from "../components/AppLoader";
import ContentWrapper from "../components/ContentWrapper";
import ExportCsv from "../components/ExportCsv";
import FilterNav from "../components/FilterNav";
import NoResults from "../components/NoResults";
import PageHeading from "../components/PageHeading";
import TableAdvanced from "../components/TableAdvanced";
import { chipsetsCells } from "../constants";
import allRoutes from "../constants/routes";
import { useSaveSearchMutation } from "../store/apis/save";
import { useGetChipsetsQuery, useLazyExportChipsetsQuery } from "../store/apis/search";
import { selectSaveToken } from "../store/slices/auth";
import { selectChipsetsSlice, setPage, setRowsPerPage, setSavedValues, setSortBy } from "../store/slices/chipsets";
import { selectUiState, setDense, setFontSize } from "../store/slices/ui";
import { displayDate, mapValuesToSaveData } from "../utils";
import { isEqual } from "lodash";
import SaveSearch from "../components/SaveSearch";

const ChipsetsSearch: React.FC = () => {
  const dispatch = useDispatch();
  const { dense, fontSize, exportFields } = useSelector(selectUiState);
  const token = useSelector(selectSaveToken);
  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();
  const [trigger, { data: download, isLoading: isExporting }] = useLazyExportChipsetsQuery();
  const { sortDesc, sortBy, rowsPerPage, page, values, lastSaved } = useSelector(selectChipsetsSlice);
  const {
    data = { results: [], total: 0 },
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetChipsetsQuery({
    rowsPerPage,
    page,
    values,
    sortBy,
    sortDirection: sortDesc ? "desc" : "asc",
  });

  const { results = [], total = 0 } = data;
  const pages = Math.ceil(total / rowsPerPage);
  const loading = isUninitialized || isLoading || isFetching;

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 5, data: mapValuesToSaveData(5, values) });
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
        </Grid>
        <Grid item>
          <ExportCsv source={download} loading={isExporting} onClick={() => trigger({values: values, fields: exportFields})} />
        </Grid>
      </Grid>
    </Box>
  );
  return (
    <>
      <Helmet title="Chipsets - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={allRoutes.chipsets.label}
        icon={allRoutes.chipsets.icon}
        color={allRoutes.chipsets.color}
        section="chipsets"
        inset
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.chipsets.path}>Chipsets</Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs zeroMinWidth>
            <Box paddingY={10}>
              <Grid container spacing={8} alignItems="flex-end" wrap="nowrap">
                <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">{total.toLocaleString()} Chipsets</Typography>
                </Grid>
                {values.startDate && (
                  <Grid item zeroMinWidth>
                    <Typography variant="subtitle2" gutterBottom>
                      Date range of rows
                    </Typography>
                    <Typography variant="h4">{`${displayDate(values.startDate)} to ${displayDate(values.endDate)}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
            {renderButtons()}
          </Grid>
        </Grid>
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          <Divider />
          {renderButtons()}
        </ContentWrapper>
      </Box>
      <Divider />
      <AppLoader loading={loading} />
      <ContentWrapper body inset>
        {!loading && !results.length ? (
          <NoResults />
        ) : (
          <TableAdvanced
            title={`GAMBoD Chipsets Search - ${total.toLocaleString()} results, page ${page}`}
            dense={dense}
            rows={results}
            fontSize={fontSize}
            cellsMap={chipsetsCells(sortBy, sortDesc)}
            page={page}
            pages={pages}
            rowsPerPage={rowsPerPage}
            loading={loading}
            setRowsPerPage={value => {
              dispatch(setRowsPerPage(value));
            }}
            setPage={value => {
              dispatch(setPage(value));
            }}
            setFontSize={value => {
              dispatch(setFontSize(value));
            }}
            setDense={value => {
              dispatch(setDense(value));
            }}
            onOrder={value => {
              dispatch(setSortBy(value));
            }}
          />
        )}
      </ContentWrapper>
    </>
  );
};

export default ChipsetsSearch;
