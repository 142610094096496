import React from "react";
import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Pagination } from "@mui/material";

import Select from "../Select";

const usePaginationStyles = makeStyles(theme => ({
  ul: {
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "initial",
    },
  },
}));

interface TablePaginationProps {
  rowsPerPage: number;
  pages: number;
  page: number;
  disabled?: boolean;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({ rowsPerPage, pages, page, disabled, setRowsPerPage, setPage }) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const paginationStyles = usePaginationStyles();

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <Box paddingY={4}>
            <Grid container spacing={8} alignItems="center" wrap="nowrap">
              <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography noWrap>Rows per page</Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      name="rows-per-page"
                      value={rowsPerPage}
                      options={[
                        {
                          label: "10",
                          value: 10,
                        },
                        {
                          label: "20",
                          value: 20,
                        },
                        {
                          label: "50",
                          value: 50,
                        },
                        {
                          label: "100",
                          value: 100,
                        },
                      ]}
                      onChange={value => {
                        setRowsPerPage(Number(value));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography component="span">Page</Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      name="jump-to-page"
                      value={page <= pages ? page : ""}
                      disabled={pages < 2}
                      options={Array.from(Array(pages)).map((_, index) => ({
                        label: `${index + 1}`,
                        value: index + 1,
                      }))}
                      onChange={value => {
                        setPage(Number(value));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {pages > 0 && (
          <Grid item xs={12} md="auto">
            {tablet && <Divider />}
            <Box paddingY={4} textAlign="center">
              <Pagination
                size={mobile ? "small" : "medium"}
                boundaryCount={1}
                classes={paginationStyles}
                count={pages}
                page={page}
                showFirstButton
                showLastButton
                disabled={disabled}
                onChange={(_, page) => {
                  setPage(page);
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TablePagination;
