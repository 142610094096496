import React from "react";
import { Chip, TextField, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";

export interface FilterAutocompleteProps {
  name?: string;
  label?: string;
  placeholder?: string;
  value: any[];
  options: any[];
  disabled?: boolean;
  limitTags?: number;
  onChange: (value: any[]) => void;
}

const FilterAutocomplete: React.FC<FilterAutocompleteProps> = ({
  name = "autocomplete",
  label,
  placeholder,
  value,
  disabled,
  limitTags = 8,
  options,
  onChange,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    root: {
      marginBottom: theme.spacing(4),
    },
  }));
  return (
    <Autocomplete
      classes={useStyles()}
      defaultValue={[]}
      multiple
      id={name}
      options={options}
      disabled={disabled}
      value={value}
      limitTags={limitTags}
      disableCloseOnSelect
      style={{ width: "100%", maxWidth: 320 }}
      getOptionLabel={option => option.title || option.model || ""}
      //@ts-ignore
      onChange={(_: Event, values: any[]) => {
        onChange(values);
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip /* {...getTagProps({ index })} */ key={`${option.title}${index}`} label={option.title || option.model || ""} variant="outlined" size="small" />
        ));
      }}
      renderInput={(params) => <TextField {...params} key={`${label}`} label={label} variant="outlined" size="small" placeholder={placeholder} fullWidth />}
    />
  );
};
export default FilterAutocomplete;
