import { Menu } from "@mui/icons-material";
import { AppBar, Box, Grid, IconButton, Link, List, ListItem, ListItemText, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch } from "react-redux";

import { navigation } from "../../constants";
import { signOut } from "../../store/slices/auth";
import { toggleMobileNavOpen } from "../../store/slices/ui";
import { variants } from "../../theme/variants";
import HeaderLogo from "../HeaderLogo";

const HeaderNav: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const useListItemStyles = makeStyles(() => ({
    root: {
      transition: "150ms color",
      color: "rgba(255, 255, 255, 0.5)",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.common.white,
      },
    },
  }));
  const listItemStyles = useListItemStyles();

  return (
    <ThemeProvider theme={variants.DARK}>
      <AppBar
        position="static"
        style={{
          background: "#11151c",
        }}
      >
        <Box height={tablet ? 56 : 96} width="100%" display="flex" alignItems="center">
          <Grid container alignItems="center" justifyContent={!tablet ? "flex-end" : "space-between"}>
            <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
              <HeaderLogo />
            </Grid>
            <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
              <Box paddingX={2}>
                <IconButton data-testid="hamburger" size="medium" onClick={() => dispatch(toggleMobileNavOpen())}>
                  <Menu />
                </IconButton>
              </Box>
            </Grid>
            <Grid item sx={{ display: { xs: "none", lg: "block" } }}>
              <Box paddingX={4}>
                <List style={{ padding: 0, display: "flex" }} component="div">
                  {navigation.map((item, index) => (
                    <ListItem
                      key={`sdfw${index}`}
                      color="textSecondary"
                      component={Link}
                      href={item.url}
                      classes={listItemStyles}
                      target="_blank"
                    >
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{
                          component: "span",
                          variant: "h5",
                          noWrap: true,
                        }}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    key={navigation.length}
                    style={{ padding: "0 15px", cursor: "pointer" }}
                    color="textSecondary"
                    onClick={() => {
                      dispatch(signOut());
                    }}
                    classes={listItemStyles}
                  >
                    <ListItemText
                      data-testid="sign-out"
                      primary="Sign out"
                      primaryTypographyProps={{
                        component: "span",
                        variant: "h5",
                        noWrap: true,
                      }}
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </ThemeProvider>
  );
};

export default HeaderNav;
