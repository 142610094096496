import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useGetVerticalsQuery } from "../../store/apis/search";
import { useDispatch, useSelector } from "react-redux";
import { selectPrivateNetworksSlice, setVertical, setValues as setNetworksValues } from "../../store/slices/privateNetworks";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import allRoutes from "../../constants/routes";
export interface TreeChartProps {
  title?: string;
  data: any[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

const TreeChart: React.FC<TreeChartProps> = ({ data = [] }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { data: verticalsOptions = [], isLoading: isVerticalsLoading } = useGetVerticalsQuery();
  const { values } = useSelector(selectPrivateNetworksSlice);
  const verticalsWithChildren = verticalsOptions.filter(item => item.subvertical.length > 0);
  const isVerticalSelected = verticalsWithChildren.find(item => item.id === values.verticals[0]);
  const curVertical = isVerticalSelected?.title ?? "";
  // const [refreshing, setrefreshing] = useState(false);
  // console.log("TreeChart");
  // console.log("values", values, data);

  const options = {
    treemap: {
      dataLabels: {
        format: "truncate",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      height: 560,
      events: {
        // @ts-ignore
        click: function (event, chartContext, opts) {
          const elementIndex = opts.dataPointIndex;
          // console.log("clicked", elementIndex, data[0].data[elementIndex]);
          if (data[0].data[elementIndex]) {
            const verticalTitle = data[0].data[elementIndex].x;
            const selectedVertical = verticalsWithChildren.find(item => item.title === verticalTitle);
            if (selectedVertical) { // vertical with subverticals
              // console.log("drill down in tree map");
              dispatch(setVertical([selectedVertical.id]));
            } else {
              // console.log("go back to table");
              const verticalObject = verticalsOptions.find(item => item.title === verticalTitle);
              if (verticalObject) { // vertical without subverticals
                dispatch(setNetworksValues({ ...values, verticals: verticalObject ? [verticalObject.id] : [] }));
              } else { // subvertical
                const foundSubvertical = verticalsWithChildren.find(item =>
                  item.subvertical.find(subItem => subItem.title === verticalTitle),
                );
                if (foundSubvertical) {
                  const subverticalObject = foundSubvertical.subvertical.find(subItem => subItem.title === verticalTitle);
                  dispatch(setNetworksValues({ ...values, verticals: subverticalObject ? [subverticalObject.id] : [] }));
                }
              }
              navigate(`${allRoutes.privateNetworks.path}/search`);
            }
          }
        },
      },
    },
    title: {
      text: " - ",
    },
    dataLabels: {
      distributed: true,
      style: {
        fontSize: "24px",
      },
      formatter: (input: string): string => (input.length > 10 ? `${input.substring(0, 10)}...` : input),
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "14px",
      },
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.5,
        reverseNegativeShade: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 5,
              color: "#ecdc4c",
            },
            {
              from: 5.001,
              to: 10,
              color: "#e9f288",
            },
            {
              from: 10.001,
              to: 20,
              color: "#c4dd4d",
            },
            {
              from: 20.001,
              to: 30,
              color: "#28f814",
            },
            {
              from: 30.001,
              to: 50,
              color: "#19c736",
            },
            {
              from: 50.001,
              to: 60,
              color: "#11a768",
            },
            {
              from: 60.001,
              to: 70,
              color: "#174eeb",
            },
            {
              from: 70.001,
              to: 100,
              color: "#3260fb",
            },
            {
              from: 100.001,
              to: 150,
              color: "#d500f1",
            },
            {
              from: 150.001,
              to: 300,
              color: "#de2bc1",
            },
          ],
        },
      },
    },
  };
  const seeAll = () => {
    dispatch(setVertical([]));
  };

  const useButtonStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));
  const buttonStyles = useButtonStyles();

  return (
    <>
      <Box maxWidth={1280} width="100%" minHeight={300}>
        {values.verticals.length > 0 && curVertical && (
          <Box height={30} style={{ position: "absolute", zIndex: 5555, marginTop: "-7px" }}>
            Showing subverticals for <strong>{curVertical}</strong> &nbsp;
            <Button classes={buttonStyles} variant="contained" onClick={seeAll}>
              See all verticals
            </Button>
          </Box>
        )}
        <Box height={[720, 720, 640, 560]} marginBottom={8} marginTop={-15} width="100%">
          <ReactApexChart type="treemap" options={options} color="#ee238c" series={data} height={560} />
        </Box>
      </Box>
    </>
  );
};

export default TreeChart;
