import React from "react";
import { Typography } from "@mui/material";

export interface DashboardMetricsItemProps {
  title: string;
  value: number;
}

const DashboardMetricsItem: React.FC<DashboardMetricsItemProps> = ({ title, value }) => (
  <>
    <Typography variant="subtitle2" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h4">{value > 0 ? value.toLocaleString() : "--"}</Typography>
  </>
);

export default DashboardMetricsItem;
