import AllocationsSvg from "../icons/AllocationsSvg";
import ChipsetsSvg from "../icons/ChipsetsSvg";
import DevicesSvg from "../icons/DevicesSvg";
import NetworksSvg from "../icons/NetworksSvg";
import OperatorsSvg from "../icons/OperatorsSvg";

export interface RouteProps {
  id?: number;
  label: string;
  icon?: React.ReactNode;
  path: string;
  color?: string;
  permission: string;
}

export interface Routes {
  dashboard: RouteProps;
  devices: RouteProps;
  operators: RouteProps;
  publicNetworks: RouteProps;
  privateNetworks: RouteProps;
  chipsets: RouteProps;
  spectrum_assignment: RouteProps;
  my_searches: RouteProps;
}

const allRoutes: Routes = {
  dashboard: {
    id: 0,
    label: "Dashboard",
    path: "/dashboard",
    color: "#ff0000",
    permission: "read_gambod_dashboard",
  },
  devices: {
    id: 1,
    icon: <DevicesSvg />,
    label: "Devices",
    path: "/devices",
    color: "#e7362d",
    permission: "read_gambod_devices",
  },
  publicNetworks: {
    id: 2,
    icon: <NetworksSvg />,
    label: "Public Networks",
    path: "/publicNetworks",
    color: "#ee238c",
    permission: "read_gambod_public_networks",
  },
  privateNetworks: {
    id: 3,
    icon: <NetworksSvg />,
    label: "Private Networks",
    path: "/privateNetworks",
    color: "#ee238c",
    permission: "read_gambod_private_networks",
  },
  operators: {
    id: 4,
    icon: <OperatorsSvg />,
    label: "Operators",
    path: "/operators",
    color: "#7035c0",
    permission: "read_gambod_operators",
  },
  chipsets: {
    id: 5,
    icon: <ChipsetsSvg />,
    label: "Chipsets",
    path: "/chipsets",
    color: "#4daaf2",
    permission: "read_gambod_chipsets",
  },
  spectrum_assignment: {
    id: 6,
    icon: <AllocationsSvg />,
    label: "Spectrum Assignment",
    path: "/spectrum_assignment",
    color: "#24b654",
    permission: "read_gambod_allocations",
  },
  my_searches: {
    id: 7,
    label: "My Searches",
    path: "/my-searches",
    permission: "read_gambod_my_searches",
  },
};

export const sectionColors = [
  allRoutes.devices.color,
  allRoutes.publicNetworks.color,
  allRoutes.privateNetworks.color,
  allRoutes.operators.color,
  allRoutes.chipsets.color,
  allRoutes.spectrum_assignment.color,
];

export const sectionKeys = [
  "dashboard",
  "devices",
  "networks",
  "privateNetworks",
  "operators",
  "chipsets",
  "spectrum_assignment",
  "my_searches",
];

export const getRoute = (sectionId: number) => {
  return Object.values(allRoutes).find(route => route.id === sectionId);
};

export default allRoutes;
