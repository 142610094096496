import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export interface AllocationsBarLegendsItemProps {
  value: string;
  color: string;
}

const AllocationsBarLegendsItem: React.FC<AllocationsBarLegendsItemProps> = ({ value, color }) => (
  <Grid item>
    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
      <Grid item>
        <Box width={12} height={12} bgcolor={color} border="1px solid #cccccc" />
      </Grid>
      <Grid item>
        <Typography noWrap>{value}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

export default AllocationsBarLegendsItem;
