import { Box } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCountries } from "../../store/slices/privateNetworks";
import allRoutes from "../../constants/routes";

export interface TreeChartProps {
  title?: string;
  data: any[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

const TreeChart: React.FC<TreeChartProps> = ({ data = [] }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const options = {
    treemap: {
      dataLabels: {
        format: "truncate",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      height: 560,
      events: {
        // @ts-ignore
        click: function (event, chartContext, opts) {
          const elementIndex = opts.dataPointIndex;
          // console.log("clicked", elementIndex, data[0].data[elementIndex]);
          if (data[0].data[elementIndex]) {
            const countryName = data[0].data[elementIndex].x;
            // console.log(countryName, data[0].data[elementIndex].id);
            dispatch(setCountries([data[0].data[elementIndex].id]));
            navigate(`${allRoutes.privateNetworks.path}/search`);
          }
        },
      },
    },
    colors: [
      function (myObj: any) {
        // console.log("myObj", myObj, myObj.w.globals.categoryLabels[myObj.dataPointIndex]);
        // console.log(data[0].data[myObj.dataPointIndex].fillColor);
        return data[0].data[myObj.dataPointIndex].fillColor;
      },
    ],
    title: {
      text: " - ",
    },
    dataLabels: {
      distributed: true,
      style: {
        fontSize: "24px",
      },
      formatter: (input: string): string => (input.length > 10 ? `${input.substring(0, 10)}...` : input),
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "14px",
      },
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.5,
        reverseNegativeShade: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };
  return (
    <Box maxWidth={1280} width="100%">
      <Box height={[720, 720, 640, 560]} marginBottom={8} marginTop={-15} width="100%">
        <ReactApexChart type="treemap" options={options} color="#ee238c" series={data} height={560} />
      </Box>
    </Box>
  );
};

export default TreeChart;
