import { Box, Breadcrumbs, Button, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import FilterNav from "../components/FilterNav";
import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import allRoutes from "../constants/routes";
import { initialValues, setValues } from "../store/slices/operators";

const Operators: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startSearch = () => {
    dispatch(setValues(initialValues));
    navigate(`${allRoutes.operators.path}/search`);
  };

  return (
    <>
      <Helmet title="Operators - GSA GAMBoD" />
      <FilterNav />
      <PageHeading title={allRoutes.operators.label} icon={allRoutes.operators.icon} color={allRoutes.operators.color} section="operators">
        <Breadcrumbs>
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Typography>Operators</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper body inset>
        <Box marginY={16} maxWidth={640}>
          <Typography variant="subtitle1">
            The latest data on mobile operators globally. Check what technologies, spectrum and devices are supported by each MNO.
          </Typography>
        </Box>
        <Box marginY={16}>
          {/* @ts-ignore */}
          <Button variant="contained" color="operators" onClick={startSearch} data-testid="searchButton">
            Start New Search
          </Button>
        </Box>
      </ContentWrapper>
      <Divider />
      <SaveList sectionId={4} />
    </>
  );
};

export default Operators;
