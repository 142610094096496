export const getPallette = (total: number) => {
  //Colours from https://eleanormaclure.files.wordpress.com/2011/03/colour-coding.pdf
  const kellyColors = [
    "hsl(42,100%,50%)",
    "hsl(310,34.7%,37.3%)",
    "hsl(24,100%,50%)",
    "hsl(212,38%,44.7%)",
    "hsl(350,100%,37.8%)",
    "hsl(36,52.4%,59.6%)",
    "hsl(22,11.7%,45.3%)",
    "hsl(145,100%,24.5%)",
    "hsl(349,87.7%,71.4%)",
    "hsl(204,100%,27.1%)",
    "hsl(11,100%,68%)",
    "hsl(265,37.9%,34.7%)",
    "hsl(33,100%,50%)",
    "hsl(342,63.5%,42.9%)",
    "hsl(49,100%,47.8%)",
    "hsl(6,81.4%,27.5%)",
    "hsl(68,100%,33.3%)",
    "hsl(26,61.8%,21.6%)",
    "hsl(11,88.8%,51%)",
    "hsl(85,33.3%,12.9%)",
  ];
  const colors = [];
  for (let ii = 0; ii < total; ii++) {
    colors.push(kellyColors[ii % kellyColors.length]);
  }

  return colors;
};
