import { SvgIcon } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const DevicesSvg: React.FC = () => {
  const useIconStyles = makeStyles(() => ({
    root: {
      fontSize: "1.5rem",
    },
  }));
  const iconStyles = useIconStyles();

  return (
    <SvgIcon classes={iconStyles} viewBox="0 0 18.59 30.6">
      <path
        d="M32.29,10.7H19.71a3,3,0,0,0-3,3V38.3a3,3,0,0,0,3,3H32.29a3,3,0,0,0,3-3V13.7A3,3,0,0,0,32.29,10.7Zm1.64,27.6a1.64,1.64,0,0,1-1.64,1.64H19.71a1.64,1.64,0,0,1-1.64-1.64V35.78H33.93Zm0-3.88H18.07V15.06H33.93ZM18.07,13.7a1.64,1.64,0,0,1,1.64-1.64H32.29a1.64,1.64,0,0,1,1.64,1.64Z"
        transform="translate(-16.71 -10.7)"
        fill="#fff"
      />
      <path d="M26,38.74a1,1,0,1,0-1-1A1,1,0,0,0,26,38.74Z" transform="translate(-16.71 -10.7)" fill="#fff" />
    </SvgIcon>
  );
};

export default DevicesSvg;
