import React, { useEffect, useCallback, useRef } from "react";
import throttle from "lodash/throttle";
import { Box, CircularProgress } from "@mui/material";

interface AllocationLoaderProps {
  loading?: boolean;
  setInView: () => void;
}

const AllocationLoader: React.FC<AllocationLoaderProps> = ({ loading, setInView }) => {
  const ref = useRef<HTMLDivElement>(null);

  const checkView = useCallback(() => {
    if (ref.current && ref.current.getBoundingClientRect().top < window.innerHeight) {
      setInView();
    }
  }, [setInView]);

  useEffect(() => {
    checkView();

    const handleScroll = throttle(checkView, 10);

    window.addEventListener("scroll", handleScroll);

    return () => {
      handleScroll.cancel();

      window.removeEventListener("scroll", handleScroll);
    };
  }, [checkView]);

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <Box height={120} width="100%" display="flex" justifyContent="center" alignItems="center">
        {!loading && <CircularProgress />}
      </Box>
    </div>
  );
};

export default AllocationLoader;
