import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  useMediaQuery,
  useTheme,
  Switch as MuiSwitch,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, Print, Remove } from "@mui/icons-material";
import { isEqual } from "lodash";
import { selectUiState, setExportFields } from "../../store/slices/ui";
import Table, { TableCell } from "../Table";
import TablePagination from "../TablePagination";
import { useDispatch, useSelector } from "react-redux";

export interface TableAdvancedProps {
  title?: string;
  cellsMap: TableCell[];
  rows: any[];
  rowsPerPage: number;
  pages: number;
  page: number;
  fontSize?: number;
  maxWidth?: number | string;
  dense: boolean;
  loading: boolean;
  setDense: (value: boolean) => void;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  setFontSize: (value: number) => void;
  onOrder?: (value: string) => void;
}

const TableAdvanced: React.FC<TableAdvancedProps> = ({
  title = "Table",
  cellsMap,
  rows = [],
  rowsPerPage,
  pages,
  page,
  dense = false,
  fontSize = 1,
  maxWidth = "none",
  setPage,
  setFontSize,
  setDense,
  setRowsPerPage,
  onOrder = () => null,
}) => {
  const dispatch = useDispatch();
  const { exportFields } = useSelector(selectUiState);
  const [cells2, setCells2] = useState(cellsMap);
  const [cells, setCells] = useState(cells2.map(cell => cell.id));
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const userFormGroupStyles = makeStyles(() => ({
    root: {
      [theme.breakpoints.down("sm")]: {
        flexWrap: "nowrap",
      },
    },
  }));

  const useLabelStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));
  const formGroupStyles = userFormGroupStyles();
  const labelStyles = useLabelStyles();

  useEffect(() => {
    if (!isEqual(cellsMap, cells2)) {
      setCells(
        cellsMap
          .map(cell => cell.id)
          .filter(cell => cells.includes(cell) || (cellsMap.some(c => cell === c.id) && !cells2.some(c => cell === c.id)))
      );
      setCells2(cellsMap);
    }
  }, [cells2, cellsMap, cells]);
  
  useEffect(() => {
    // console.log(exportFields, cells, JSON.stringify(exportFields) !== JSON.stringify(cells))
    if (JSON.stringify(exportFields) !== JSON.stringify(cells)) {
      dispatch(setExportFields(cells));
    } 
  }, [cells]);

  const handleUpdateCells = (id: string, checked: boolean) => {
    setCells(checked ? [...cells, id] : cells.filter(cell => cell !== id));
  };

  const fontMinus = () => {
    setFontSize(Math.max(fontSize - 1, 0));
  };

  const fontPlus = () => {
    setFontSize(Math.min(fontSize + 1, 3));
  };

  const print = (title: string) => {
    const element = document.getElementById("table-div");

    if (element) {
      const doc = window.open();

      if (doc) {
        doc.document.write("<html><head>");
        doc.document.write(`<title>${title}</title>`);
        doc.document.write(
          "<style>body{margin:0;font-family:Arial, Helvetica, sans-serif;}table, td, th {border: 1px solid #cccccc;}table{border-collapse:collapse;}table td,table th{padding:4px;text-align:left;font-size:0.7rem;}table td,table td * {vertical-align:top;}svg{display: none;}</style>"
        );
        doc.document.write("</head><body>");
        doc.document.write(`<h3>${title}</h3>`);
        doc.document.write(element.innerHTML);
        doc.document.write("</body></html>");
        doc.document.close();
        doc.focus();
        doc.print();
        doc.close();
      }
    }
  };

  return (
    <>
      {cells2.length > 3 && (
        <>
          <Box overflow="auto">
            <Box paddingY={4}>
              <FormGroup row classes={formGroupStyles}>
                {cells2.map(cell => (
                  <FormControlLabel
                    classes={labelStyles}
                    key={cell.id}
                    control={
                      <Checkbox
                        name={cell.id}
                        color="primary"
                        onChange={event => {
                          handleUpdateCells(cell.id, event.target.checked);
                        }}
                      />
                    }
                    label={cell.label}
                    checked={cells.includes(cell.id)}
                  />
                ))}
              </FormGroup>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* {JSON.stringify(exportFields)} */}
      <TablePagination page={page} pages={pages} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      <Divider />
      <Box paddingTop={4}>
        <Table
          id="table-div"
          maxWidth={maxWidth}
          cellsMap={cells2.filter(cell => cells.includes(cell.id))}
          dense={tablet || dense}
          rows={rows}
          onOrder={onOrder}
          fontSize={fontSize}
        />
      </Box>
      <TablePagination page={page} pages={pages} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      <Divider />
      <Box paddingY={4}>
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <Grid container spacing={8} alignItems="center" wrap="nowrap">
              <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <IconButton onClick={fontMinus} disabled={fontSize === 0} size="large">
                      <Remove />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={fontPlus} disabled={fontSize === 3} size="large">
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography>Font size</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!tablet && (
                <Grid item>
                  <FormControlLabel
                    classes={labelStyles}
                    label="Dense padding"
                    control={
                      <MuiSwitch
                        checked={dense}
                        color="primary"
                        onChange={(_, checked) => {
                          setDense(checked);
                        }}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                print(title);
              }}
              size="large"
            >
              <Print />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TableAdvanced;
