import { Box, Breadcrumbs, Button, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import PageHeading from "../components/PageHeading";
import SaveList from "../components/SaveList";
import routes from "../constants/routes";
import { resetValues } from "../store/slices/networks";

const Networks: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const startSearch = () => {
    dispatch(resetValues());
    navigate(`${routes.publicNetworks.path}/search`);
  };

  return (
    <>
      <Helmet title="Networks - GSA GAMBoD" />
      <PageHeading title={routes.publicNetworks.label} icon={routes.publicNetworks.icon} color={routes.publicNetworks.color} section="nts">
        <Breadcrumbs>
          <Link component={NavLink} to={routes.dashboard.path}>
            Dashboard
          </Link>
          <Typography>{routes.publicNetworks.label}</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper body inset>
        <Box marginY={16} maxWidth={640}>
          <Typography variant="subtitle1">
            The latest updates on mobile networks globally across a growing number of network categories. Multiple search criteria and
            filtering of results to refine your searches. Graphical outputs for use in presentations and reports.
          </Typography>
        </Box>
        <Box marginY={16}>
          {/* @ts-ignore */}
          <Button variant="contained" color="privateNetworks" onClick={startSearch} data-testid="searchButton">
            Start New Search
          </Button>
        </Box>
      </ContentWrapper>
      <Divider />
      <SaveList sectionId={2} />
    </>
  );
};

export default Networks;
