import React from "react";

export interface LogoSvgProps {
  size?: "small" | "medium";
}

const LogoSvg: React.FC<LogoSvgProps> = ({ size = "medium" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.04 48" height={size === "small" ? 32 : 48}>
    <path
      fill="#ffffff"
      d="M18.57,17.59a33.13,33.13,0,0,1,6,.66,18.44,18.44,0,0,0,2.89.39c0,1.32.36,3.57.59,7a1,1,0,0,1-1.45.09c-1-4.26-4.2-6.37-8.67-6.37-8,0-11.8,5.58-11.8,13,0,3.52.81,7.29,3.25,10.11a11.7,11.7,0,0,0,8.73,3.73c2.16,0,3.47-.35,4-1a4.73,4.73,0,0,0,.4-2.36V40.53c0-2.68-.15-3-2.73-3.46L19.47,37l-1.63-.26a.93.93,0,0,1,.05-1.27c1.76.08,3.93.13,7.05.13,2,0,3.57,0,5.51-.13a.79.79,0,0,1,.21,1.15l-.12.12-.81.13c-1.59.21-1.92.58-1.94,2.93v2.92c0,1.54.23,2.68,1.09,3.07a.53.53,0,0,1-.05.66,10.5,10.5,0,0,0-2.16.35A44.93,44.93,0,0,1,17.89,48C12.47,48,7.36,46.6,4,43.17A14.23,14.23,0,0,1,0,33.1,14.54,14.54,0,0,1,4.16,22.77C7.36,19.48,12.29,17.59,18.57,17.59Zm29.4,0a31.18,31.18,0,0,1,6.19.92,17.92,17.92,0,0,1,1,5.94,1.12,1.12,0,0,1-1.45.13c-.68-2.29-2.26-5.23-6.1-5.23-3.61,0-5.1,2.28-5.1,4.61,0,1.75,1.17,3.61,3.81,5.1l.48.26,2.8,1.45c2.94,1.5,6.19,4.22,6.19,8.66,0,5-3.93,8.57-10.48,8.57a14,14,0,0,1-7.41-1.84c-.81-.93-1.4-4-1.35-6.68a1,1,0,0,1,1.4-.18c.81,2.24,3.25,6.94,7.67,6.94,3.44,0,5.38-2,5.38-4.7,0-2.38-1.1-4.33-4.14-6.11l-.47-.26L44.4,34.11C41,32.27,38,29.59,38,25.63,38,21.28,41.38,17.59,48,17.59Zm27.92,0a.92.92,0,0,1,.77.35c.71,2,1.3,4,2,6h0l6.5,18.42c1,2.94,1.27,3.25,3.57,3.64h0l.68.14a.92.92,0,0,1,0,1.27c-1.85-.09-3.52-.13-5.87-.13-1.94,0-3.93.08-5.51.13A1,1,0,0,1,78,46.16h0L79,46c1.62-.17,1.76-.44,1.17-2.33h0l-2.12-6c-.45-1.19-.59-1.19-1.86-1.19H69.93c-1,0-.94.08-1.32,1.19h0L66.76,43c-.9,2.59-.72,2.73.95,2.95h0l1.31.22a.91.91,0,0,1-.09,1.27c-1.45-.09-2.94-.09-4.79-.13-1.49,0-2.89,0-4.47.13a1,1,0,0,1-.05-1.27h0L61,45.94c1.31-.18,1.8-.58,2.48-2,.54-1.14,1.27-3,2.22-5.36h0l6.19-15.64a6.84,6.84,0,0,0,.85-3.21C74.31,19.39,75.8,17.59,75.89,17.59Zm-2.4,7.07H73.4c-.18.31-.54,1.41-1.35,3.48h0l-1.9,5.14c-.5,1.27-.36,1.27.72,1.27h4.94c1,0,1.18-.16.85-1.14h0l-1.91-5.27a19.88,19.88,0,0,0-1.26-3.48Z"
    />
    <path
      fill="#f79400"
      d="M69.94,11.37c26.64-3.79,33.87,6.9,32.86,24a.47.47,0,0,1-.93,0c0-15.33-9.94-24.61-32.16-23.24C69.18,12.2,68.79,11.53,69.94,11.37Zm39.24,14a.39.39,0,0,1-.77.05C108.2,12.64,99.76,5.05,81.26,6.51c-.43,0-.77-.53.19-.68C103.59,2.3,109.77,11.1,109.18,25.37ZM114,16.94c0,.43-.63.35-.64,0-.18-10.65-7.2-17-22.62-15.76-.36,0-.64-.44.15-.57C109.35-2.29,114.51,5,114,16.94Z"
    />
  </svg>
);

export default LogoSvg;
