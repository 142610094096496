const colors = {
  white: "#ffffff",
  primary: "#fa7332",
  secondary: "#2f77ea",
  tertiary: "#f79400",
  background: "#f1f4f7",
  bar1: "#c27fd0",
  bar2: "#f4c79f",
  bar3: "#F19646",
  greys: {
    50: "#e8e8e9",
    100: "#cfd0d2",
    150: "#b8b9bb",
    200: "#a0a1a4",
    250: "#888a8e",
    300: "#707377",
    350: "#595c61",
    400: "#414449",
    450: "#292d33",
    475: "#1d2128",
    500: "#11151c",
  },
};

export default colors;
